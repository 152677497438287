import SearchForm from '../SearchForm/SearchForm';
import { renderProfileIndicator } from '../Shared/ProfileIndicator';
import * as React from 'react';
import { useState } from 'react';
import displayComingSoonToast from '../../../Util/ComingSoon';
import { MAT_VERSION } from 'Constants';
// import { version } from '@package';

export default function HelpPageComponent() {
	const [search, setSearch] = useState({ searchTerm: '' });

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	return (
		<div className="help-page padded-page">
			<div className="row header-row">
				<h2 className="column page-title">
					Help
				</h2>
				<SearchForm
					model={search}
					onClick={displayComingSoonToast}
					onChange={onSearchTriggered}
					label="A search for entities"
					classNameSuffix="import-collection"
				/>
				{renderProfileIndicator()}
			</div>

			<div className="help-links row">
				<div className="column">
					<h5>Information</h5>
					<ul>
						<li>
							<a
								href="https://solutionlinkagecare.hcma.com.au/pages/viewpage.action?pageId=28443604"
								target="_blank"
								rel="noreferrer"
								className="icon-link-external icon-right"
							>
								Documentation
							</a>
						</li>
						<li>
							<a
								href="https://solutionlinkagecare.hcma.com.au/display/ADVC/Hitachi+AHS+-+Release+Note+Current"
								target="_blank"
								rel="noreferrer"
								className="icon-link-external icon-right"
							>
								Release notes
							</a>
						</li>
						<li>
							<div className='about'>
								<div>About</div>
								<div className='mat-version'>ver {MAT_VERSION}</div>
							</div>
						</li>
					</ul>
				</div>

				<div className="column">
					<h5>User Guides</h5>
					<ul>
						<li>
							<a onClick={displayComingSoonToast}>
								Keyboard shortcuts
							</a>
						</li>
						<li>
							<a onClick={displayComingSoonToast}>
								Tutorials
							</a>
						</li>
						<li>
							<a onClick={displayComingSoonToast}>
								Productivity tips
							</a>
						</li>
					</ul>
				</div>

				<div className="column">
					<h5>Service Desk</h5>
					<ul>
						<li>
							<a
								href="https://solutionlinkagecarej.hcma.com.au/plugins/servlet/desk/portal/3/create/61"
								target="_blank"
								rel="noreferrer"
								className="icon-link-external icon-right"
							>
								Report an issue
							</a>
						</li>
						<li>
							<a
								href="https://solutionlinkagecarej.hcma.com.au/plugins/servlet/desk/portal/3/create/59"
								target="_blank"
								rel="noreferrer"
								className="icon-link-external icon-right"
							>
								Provide feedback
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
