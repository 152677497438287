import axios from "axios";
import { SERVER_URL } from "Constants";
import {AreaEntity, LinkEntity} from "Models/Entities";
import { NodeGraphic, Link } from "Views/MapComponents";
import MapController from "../MapController";

interface IDynamicConnectionsResult
{
	entryNodeIds: string[];
	exitNodeIds: string[];
	returnMessage: string;
}

/**
 * Helper class for displaying Pixi objects that are dynamically scaled according to zoom level
 */
export default class DynamicScaleObjectHelper {

	/**
	 * This method must be run when there are updates to the associated entity (and by extension map obj).
	 * Works by hiding and reshowing the view (with side-effect of displaying graphics with the new info)
	 *
	 * IMPORTANT: setDynamicConnectionsView is async as it may call getDynamicConnections
	 * 
	 * @param controller 
	 */
	public static updateDynamicObjectDisplay(controller: MapController, needRerender: boolean = true) {
		const lookup = controller.getMapLookup();
		if (lookup.getDynamicScaleObjects().length > 0) {
			// Toggle on and off to refresh data when connectivity is updated
			if (lookup.isDisplayConnectivityEndpoint) {
				this.setConnectivityEndpointView(false, controller, false);
				this.setConnectivityEndpointView(true, controller, needRerender);
			}
			if (lookup.isDisplaySpeedLimits) {
				this.setSpeedLimitsView(false, controller, false);
				this.setSpeedLimitsView(true, controller, needRerender);
			}

			if (lookup.isDisplayDynamicConnections) {
				this.setDynamicConnectionsView(false, controller, false);
				this.setDynamicConnectionsView(true, controller, needRerender);
			}
		}

		if (!lookup.isDisplayDynamicConnections) {
			this.setDynamicConnectionsView(false, controller, needRerender);
		}
	}

	/**
	 * @param status true for show
	 * @param controller 
	 * @param isRerender true by default. only set to false when there are additional updates
	 * to follow that will need render (so that rerender is only called once)
	 */
	public static setDynamicConnectionsView(status: boolean, controller: MapController, isRerender: boolean = true) {
		const renderer = controller.getMapRenderer();
		const lookup = controller.getMapLookup();

		// Only show the dynamic connections if there are no errors on the map
		if (lookup.getObjectErrorCount(AreaEntity) > 0) {
			status = false;
		}

		lookup.isDisplayDynamicConnections = status;
		const objIds: string[] = [];
		const isUpdateInitialScale = renderer.isUpdateDynamicScaleObjects();

		if (status) {
			// display dynamic connections
			const { entryNodeIds, exitNodeIds } = lookup.getEntryAndExitNodeIds();
			const graphics: NodeGraphic[] = [];
			const getNodeGraphicAndAddToObjIds = (id: string) => {
				const ng = lookup.getMapObjectByEntityId(id, 'node') as NodeGraphic;
				if (!!ng) {
					ng.isDisplayDynamicConnections = true;
					graphics.push(ng);
					return ng;
				}

				return undefined;
			};

			entryNodeIds?.forEach(id => {
				const node = getNodeGraphicAndAddToObjIds(id);
				if (!!node) {
					node.isEntryNode = true;
				}
			});
			exitNodeIds?.forEach(id => {
				const node = getNodeGraphicAndAddToObjIds(id);

				if (!!node) {
					node.isExitNode = true;
				}
			});
			graphics.forEach(ng => {
				// Flag that determines whether or not to render as connectivity endpoint
				if (isUpdateInitialScale) {
					ng.updateScale();
				}
				// Object ids for rerender unpon zoom
				objIds.push(ng.getId());
				renderer.markObjectToRerender(ng.getId());
			});
		} else {
			// Reset DynamicConnections info in graphics and mark for re-render
			renderer.getContainer('node').children.forEach(o => {
				if (!!o.name) {
					const ng = renderer.getObjectById(o.name) as NodeGraphic;
					// hide
					if (!!ng && ng.isDisplayDynamicConnections === true) {
						ng.isDisplayDynamicConnections = undefined;
						if (ng.isConnectivityEndpoint !== true) {
							ng.resetScale();
							objIds.push(ng.getId()); // to remove via updateDynamicScaleObjects
						}
						renderer.markObjectToRerender(ng.getId());
						// Accounts for case where user toggles multiple times without changing zoom level
						renderer.pixiCurrentAppliedZoom = undefined;
					}
				}
			});
		}
		// Update associated lookup tables
		this.updateDynamicScaleObjects(status, objIds, controller, isRerender);
	}

	public static speedToIntx10(speed: number) {
		return Math.round(speed * 10);
	}

	public static isConstantSpeedValidToDisplay(controller: MapController, constantSpeed: number | undefined) {
		const maptoolparam = controller.getImportVersion().maptoolparam;
		if (!maptoolparam || !constantSpeed)
			return false;
		const speedInt = this.speedToIntx10(constantSpeed);
		const { maxSpeedEmpty, maxSpeedBackward, forwardSpeed } = maptoolparam;
		if (speedInt > 0) {
			const speed = this.speedToIntx10(Math.min(maxSpeedEmpty, forwardSpeed));
			return speedInt < speed;
		} else {
			const speed = this.speedToIntx10(maxSpeedBackward);
			return speedInt > speed;
		}
	}

	/**
	 * Show or hide speed limits
	 * Changes styling and updates scale dynamically by setting the isDisplaySpeedLimit flag of Link
	 * Scaling is controlled by updateDynamicScaleObjects, which is also called on each zoom update
	 * 
	 * @param status true to show
	 * @param controller 
	 * @param isRerender 
	 */
	public static setSpeedLimitsView(status: boolean, controller: MapController, isRerender: boolean = true) {
		const renderer = controller.getMapRenderer();
		const lookup = controller.getMapLookup();
		lookup.isDisplaySpeedLimits = status;
		const objIds: string[] = [];
		const graphics: Link[] = []; 
		const isUpdateInitialScale = renderer.isUpdateDynamicScaleObjects();
		if (status) {
			lookup.getAllEntities(LinkEntity).forEach(linkEntity => {
				if (linkEntity.isDefaultSpeed === false && this.isConstantSpeedValidToDisplay(controller, linkEntity.constantSpeed)) {
					const link = lookup.getMapObjectByEntityId(linkEntity.id, 'link') as Link;
					if (!!link) {
						link.isDisplaySpeedLimit = true;
						if (isUpdateInitialScale) {
							link.updateScale();
						}
						renderer.markObjectToRerender(link.getId());
						objIds.push(link.getId());
						graphics.push(link);
					}
				}
			});
		} else {
			// Set isDisplaySpeedLimit to false for every Link object and reset applied zoom
			renderer.getContainer('link').children.forEach(o => {
				if (!!o.name) {
					const linkObj = renderer.getObjectById(o.name) as Link;
					// hide
					if (!!linkObj && linkObj.isDisplaySpeedLimit === true) {
						linkObj.isDisplaySpeedLimit = false;
						objIds.push(linkObj.getId()); // to remove via updateDynamicScaleObjects
						renderer.pixiCurrentAppliedZoom = undefined;
					}
				}
			});	
		}
		this.updateDynamicScaleObjects(status, objIds, controller, isRerender);
	}

	/**
	 * Show or hide connectivity endpoints
	 * Changes styling and updates scale dynamically by setting the isConnectivityEndpoint flag of NodeGraphic
	 * Scaling is controlled by updateDynamicScaleObjects, which is also called on each zoom update
	 * 
	 * @param status true to show
	 * @param controller 
	 * @param isRerender 
	 */
	public static setConnectivityEndpointView(status: boolean, controller: MapController, isRerender: boolean = true) {
		const renderer = controller.getMapRenderer();
		const lookup = controller.getMapLookup();
		lookup.isDisplayConnectivityEndpoint = status;
		// remains empty array if status is false
		const objIds: string[] = [];
		const isUpdateInitialScale = renderer.isUpdateDynamicScaleObjects();
		renderer.getContainer('node').children.forEach(o => {
			if (!!o.name) {
				const ng = renderer.getObjectById(o.name) as NodeGraphic;
				if (status) {
					// show
					if (ng.isStartOrEnd()) {
						const link = ng.getEntity().getLink();
						if (!link) {
							// ng without associated link - should never reach here
							return;
						}
	
						if ((ng.isStart() && (!link.linkFroms || link.linkFroms.length === 0)) ||
							(ng.isEnd() && (!link.linkTos || link.linkTos.length === 0))) {
							// Flag that determines whether or not to render as connectivity endpoint
							ng.isConnectivityEndpoint = true;
							if (ng.isDisplayDynamicConnections !== true) {
	
								if (isUpdateInitialScale) {
									ng.updateScale();
								}
								// Object ids for rerender upon zoom
								objIds.push(ng.getId());
							}
							renderer.markObjectToRerender(ng.getId());	
						}
					}
				} else {
					// hide
					if (ng.isConnectivityEndpoint === true) {
						ng.isConnectivityEndpoint = undefined;
						const isDynamicConnection = ng.isDisplayDynamicConnections === true;
						if (!isDynamicConnection) {
							ng.resetScale();
							objIds.push(ng.getId()); // to remove via updateDynamicScaleObjects
						}
						renderer.markObjectToRerender(ng.getId());
						// Accounts for case where user toggles multiple times without changing zoom level
						renderer.pixiCurrentAppliedZoom = undefined;
					}
				}
			}
		});
		// list that will be updated on zoom
		this.updateDynamicScaleObjects(status, objIds, controller, isRerender);
	}

	public static updateDynamicScaleObjects(status: boolean, objIds: string[], controller: MapController, isRerender: boolean) {
		// list that will be updated on zoom
		const renderer = controller.getMapRenderer();
		const lookup = controller.getMapLookup();
		if (status) {
			lookup.addDynamicScaleObjects(objIds);
		} else {
			lookup.removeDynamicScaleObjects(objIds);
		}
		if (isRerender) {
			renderer.rerender();
		}
	}
}
