/* eslint-disable max-len */
import React, {
	useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import MapController from '../Map/MapController';
import {
	AreaEntity,
	BayEntity,
	LinkEntity,
	SublinkEntity,
	NodeEntity,
	SegmentEntity,
	BeaconEntity,
} from 'Models/Entities';
import { Button, Colors, Display } from '../../Components/Button/Button';
import { disableMapDomEvents } from '../Map/Helpers/MapDOMEvents';
import { ISearch } from '../../Components/CRUD/EntityCollection';
import SearchForm from '../../Components/SearchForm/SearchForm';
import buildAhsMapObjects, {
	categoriseAreasAndBays, convertAreaItem, convertBayItem, convertLinkItem, convertNodeItem, convertSublinkItem, dummyArea,
} from './AhsMapObjects';
import buildFmsMapObjects from './FmsMapObjects';
import setObjectDisplayAndRerender from './ObjectsDisplay';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ILayersPanelItem, LayersPanelItem, layersPanelItemId } from './LayersPanelItem';
import { areaLocationTypeOptions, areaTypeOptions } from '../../../Models/Enums';
import { Model } from '../../../Models/Model';
import MapObject, { MapObjectType } from '../Map/MapObjects/MapObject';
import { IMenuShownStatus, initialMenuShownStatus } from '../EditMap';
import { UNDEFINED_BAYS_AREANAME } from 'Constants';
import { setCustomTag } from '../Map/Helpers/MapUtils';
import { store } from 'Models/Store';
import {runInAction} from "mobx";
import AreaEditHandler, {areaMode} from "../Map/MapStateHandlers/AreaEditHandler";
import {MapObjectEntityType} from "../Map/MapStore";

interface ILayersSidePanelProps {
	map: MapController;
}

/**
 * Displays all map objects in a treeview via a collapsible
 * sidepanel on the left-hand-side of the mapedit page
 * @param props
 * @returns
 */
export function LayersSidePanel(props: ILayersSidePanelProps) {
	const [displaySidePanel, setDisplaySidePanel] = useState(true);

	useEffect(() => {
		disableMapDomEvents('object-sidebar-panel');
	});

	const toggleSidePanel = () => setDisplaySidePanel(!displaySidePanel);
	const toggleButton = (
		<Button
			className="show-hide-button no-background"
			icon={{ icon: displaySidePanel ? 'chevrons-left' : 'chevrons-right', iconPos: 'icon-bottom' }}
			colors={Colors.White}
			display={Display.Text}
			onClick={toggleSidePanel}
		/>
	);

	const sideBarView = useMemo(() => LayersSidePanelContent(props), [props]);

	return (
		<div
			id="object-sidebar-panel"
			className={classNames('object-sidebar', !displaySidePanel ? 'hide' : undefined)}
		>
			{toggleButton}
			{displaySidePanel ? sideBarView : null}
		</div>
	);
}

/**
 * Organise area entity data and render the sidebar
 */
export function LayersSidePanelContent(props: ILayersSidePanelProps) {
	const { map } = props;

	const fmsMap = buildFmsMapObjects(map.getMapLookup());

	const objects = [
		fmsMap,
		buildAhsMapObjects(map.getMapLookup()),
	];

	if (!store.isInit) {
		store.isInit = true;
		console.log(`LayersSidePanelContent: Setting isInit to true`);
	} 
	// The line below caused a performance hit by rerendering after initial render for the
	// purpose of hiding FMS objects by default. Instead, the initial state of these map objects
	// should be invisible so that the initial render is connect and extra rerender is not needed
	// setObjectDisplayAndRerender(fmsMap, map, false);

	return (
		<div className="side-panel-content">
			<LayersPanel objectItems={objects} map={map}/>
		</div>
	);
}

export interface ISelectedObjectRef {
	entityId: string;
	entityType?: MapObjectType;
	selectedViaLayers?: boolean;
}

/**
 * Actual rendering of the treeview structure
 * with Areas and Paths at the top-level of the
 * AHS map treeview
 */
const LayersPanel = ({ objectItems, map }: { objectItems: ILayersPanelItem[], map: MapController }) => {
	const [search, setSearch] = useState<ISearch>({ searchTerm: '' });
	const [searchState, setSearchState] = useState<{
		filteredObjects: ILayersPanelItem[],
		isSearched: boolean,
	}>({
		filteredObjects: objectItems,
		isSearched: false,
	});

	const [searchLastUpdate, setSearchLastUpdate] = useState(0);
	const [searchTagTimeoutId, setSearchTagTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

	let buildHierarchyToExpand: string[] = [];
	/**
	 * The expanding issue(?) exists for long time. Which is the previously expanded items have been added into hierarchyToExpand and won't be removed.
	 * If collapse all expanded items and click on a label (new selected item) just bellowing the previously expanded items,
	 * the collapsed items are expanded and push the new selected item out of the page. See HITMAT-947.
	 */
	const [hierarchyToExpand, setHierarchyToExpand] = useState<string[]>(['AHC Map', 'Areas', 'Paths']);
	const [entityIdForSelectedMapObject, setEntityIdForSelectedMapObject] = useState<ISelectedObjectRef>({ entityId: '' });
	const [inactiveEntityId, setInactiveEntityId] = useState<ISelectedObjectRef>({ entityId: '' });
	// using this state variable for custom styling of the error button on the bottom of the layers panel
	const [errorToggle, setErrorToggle] = useState(false);
	const [errorCount, setErrorCount] = useState(map.getMapLookup().getMapErrorCount());
	const [warningToggle, setWarningToggle] = useState(false);
	const [warningCount, setWarningCount] = useState(map.getMapLookup().getMapWarningCount());

	const [isViewMenuShownInLayersPanel, setIsViewMenuShownInLayersPanel] = useState<IMenuShownStatus>(initialMenuShownStatus);

	const onMapObjectSearch = (
		items: ILayersPanelItem[] | undefined,
		value: string,
		exactMatch: boolean,
		filterErrors: boolean,
		filterWarnings: boolean,
	): ILayersPanelItem[] => {
		if (items === undefined || items.length === 0) {
			return [];
		}

		const hasValue = value.length > 0;

		return items.filter(item => {
			item.filteredChildren = onMapObjectSearch(item.children, value, exactMatch, filterErrors, filterWarnings);

			const childrenMatched = item.filteredChildren && item.filteredChildren.length > 0;

			if (item.skipSearch === true) {
				return childrenMatched;
			}

			const nameMatch = exactMatch
				? item.name.toLowerCase() === value
				: item.name.toLowerCase().includes(value);

			const errorMatch = item.isError === true;
			const warningMatch = item.isWarning === true;

			const errorFilterAndMatch = filterErrors && errorMatch && !hasValue && !filterWarnings;
			const warningFilterAndMatch = filterWarnings && warningMatch && !hasValue && !filterErrors;
			const nameFilterAndMatch = hasValue && nameMatch && !filterErrors && !filterWarnings;
			const errorNameEnabledAndMatch = filterErrors && errorMatch && hasValue && nameMatch && !filterWarnings;
			const warningNameEnabledAndMatch = filterWarnings && warningMatch && hasValue && nameMatch && !filterErrors;
			const errorWarningEnabledAndMatch = (filterErrors && errorMatch || filterWarnings && warningMatch) && !hasValue;
			const allEnabledAndMatch = (filterErrors && errorMatch || filterWarnings && warningMatch) && hasValue && nameMatch;

			return errorFilterAndMatch || warningFilterAndMatch || nameFilterAndMatch
				|| errorNameEnabledAndMatch || warningNameEnabledAndMatch || errorWarningEnabledAndMatch
				|| allEnabledAndMatch || childrenMatched;
		});
	};

	const performSearch = AwesomeDebouncePromise(((searchTerm: string, filterErrors: boolean, filterWarnings: boolean) => {
		if (searchTerm.length === 0 && !filterErrors && !filterWarnings) {
			const unfilterItems = (items: ILayersPanelItem[]) => {
				items.forEach(item => {
					item.filteredChildren = undefined;

					if (!!item.children) {
						unfilterItems(item.children);
					}
				});
			};
			unfilterItems(objectItems);

			setSearchState({
				filteredObjects: objectItems,
				isSearched: false,
			});

			// Reset to the location of the selected object
			setEntityIdForSelectedMapObject({ ...entityIdForSelectedMapObject, selectedViaLayers: false });
		} else if (searchTerm.length > 1 || filterErrors || filterWarnings) {
			const searchValue = searchTerm.replace(/['"]+/g, '');
			const exactMatchSearch = (searchTerm.charAt(0) === '"' && searchTerm.charAt(searchTerm.length - 1) === '"');
			const searchResult = onMapObjectSearch(objectItems, searchValue.toLowerCase(), exactMatchSearch, filterErrors, filterWarnings);
			setSearchState({
				filteredObjects: searchResult,
				isSearched: true,
			});
		}
	}), 100);

	function debounce(fn: Function, delay = 500) {
		let timeoutId: NodeJS.Timeout | undefined;
	
		return (inputValue: string) => {
			if (!!timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				fn(inputValue);
			}, delay);
		};
	}

	// Send custom tag if there is no any changes after 500 ms
	const sentSearchCustomTag = debounce((searchTerm: string) => {
		setCustomTag('search-map-objects', searchTerm);
	}, 500);

	const handleSearchChange = (value: string) => {
		setSearch({ searchTerm: value });
		performSearch(value, errorToggle, warningToggle);
		sentSearchCustomTag(value);
	};
	// Add event listeners for the map object operations
    useEffect(() => {
        map.getEventHandler().addListener('onMapObjectSelectedInMap', handleSelection);
        map.getEventHandler().addListener('onItemSelectedInLayersPanel', handleSetInactiveEntityId);
        map.getEventHandler().addListener('onToggleItemsFromViewMenu', handleToggleItemsFromViewMenu);
        map.getEventHandler().addListener('onErrorCountUpdate', onErrorCountUpdate);
        map.getEventHandler().addListener('onUpdateMapObjectsPanel', onUpdateMapObjectsPanel);

        return () => {
            map.getEventHandler().removeListener('onMapObjectSelectedInMap', handleSelection);
            map.getEventHandler().removeListener('onItemSelectedInLayersPanel', handleSetInactiveEntityId);
            map.getEventHandler().removeListener('onToggleItemsFromViewMenu', handleToggleItemsFromViewMenu);
            map.getEventHandler().removeListener('onErrorCountUpdate', onErrorCountUpdate);
            map.getEventHandler().removeListener('onUpdateMapObjectsPanel', onUpdateMapObjectsPanel);
        };
    }, [search, errorToggle, warningToggle]);

    const onUpdateMapObjectsPanel = (entityId?: string, entityType?: MapObjectType) => {
        objectItems[1] = buildAhsMapObjects(map.getMapLookup());

        // Refresh the layers panel to show the newly added map object
        performSearch(search.searchTerm, errorToggle, warningToggle);
        setErrorCount(() => map.getMapLookup().getMapErrorCount());
        setWarningCount(() => map.getMapLookup().getMapWarningCount());

        if (!!entityId && !!entityType) {
            setTimeout(() => {
                setEntityIdForSelectedMapObject({
                    entityId,
                    entityType,
                    selectedViaLayers: false
                });
            }, 100);
        }
    };
	
	const onErrorCountUpdate = () => {
		setErrorCount(() => map.getMapLookup().getMapErrorCount());
		setWarningCount(() => map.getMapLookup().getMapWarningCount());
	};

	const handleToggleItemsFromViewMenu = (type: MapObjectType, isViewMenuShown: IMenuShownStatus, checkItems: boolean) => {
		
		if (checkItems) {
			// Rerender only once (after all objects have been marked for rerender)
			const isRerender = false;
			const toggleFromViewMenu = true;
			objectItems[1].children?.[1].children?.map(link => {
				setObjectDisplayAndRerender(link, map, link.isDisplayed ?? true, isRerender,
					isViewMenuShownInLayersPanel, toggleFromViewMenu, type);
			});
			if (searchState.isSearched) {
				searchState.filteredObjects[0].children?.[1].children?.map(link => {
					setObjectDisplayAndRerender(link, map, link.isDisplayed ?? true, isRerender,
						isViewMenuShownInLayersPanel, toggleFromViewMenu, type);
				});
			}

			map.getMapRenderer().rerender();
		}

		// TODO: refactor. No need the local isViewMenuShownInLayersPanel if using the global isViewMenuShown in MapStore
		setIsViewMenuShownInLayersPanel(isViewMenuShown);
	};

	/**
	 * Sets the entity id to make inactive
	 * Handles the expansion of the panel to selected item
	 * if the valid map-type is provided
	 * @param entityId
	 * @param entityMapType
	 */
	const handleSetInactiveEntityId = (entityId?: string, entityMapType?: string) => {
		if (!!entityId && !!entityMapType) {
			setEntityIdForSelectedMapObject(() => ({
				entityId: entityId,
				entityType: entityMapType as MapObjectType,
				selectedViaLayers: true,
			}));
			setInactiveEntityId(() => ({
				entityId: '',
			}));

			let entity: Model | undefined;
			// retaining the state of the layers panel after clearing the search box input
			switch (entityMapType) {
				case 'link':
					entity = map?.getMapLookup().getEntity(entityId, LinkEntity);
					break;

				case 'sublink':
					entity = map?.getMapLookup().getEntity(entityId, SublinkEntity);
					break;

				case 'area':
				case 'location':
					entity = map?.getMapLookup().getEntity(entityId, AreaEntity);
					const areaEditHandler : AreaEditHandler | any = map.getEventHandler().getStateHandler(); 
					runInAction(() => {
						areaEditHandler.isConfirmable = false;
					});
					if (areaEditHandler.areaMapObject === undefined) {
						const areaMapObjectId = map.getMapLookup().getMapObjectId(entityId, 'area');
						areaEditHandler.areaMapObject = map.getMapRenderer().getObjectById(areaMapObjectId);
					}
					areaEditHandler.areaMapObject.disableEditMode();
					areaEditHandler.areaMapObject.setPointsFromEntity();
					if (areaEditHandler instanceof AreaEditHandler) {
						areaEditHandler.updateArea();
					}
					areaEditHandler.mode = areaMode.SELECTED;
					break;

				case 'bay':
					entity = map?.getMapLookup().getEntity(entityId, BayEntity);
					break;

				case 'node':
					entity = map?.getMapLookup().getEntity(entityId, NodeEntity);
					break;

				case 'segment':
					entity = map?.getMapLookup().getEntity(entityId, SegmentEntity);
					break;

				case 'beacon':
					entity = map?.getMapLookup().getEntity(entityId, BeaconEntity);
					break;
			}

			if (!!entity) {
				handleSelection(entity, entityMapType as MapObjectType, true);
			}

			return;
		}
		setInactiveEntityId(() => entityIdForSelectedMapObject);
	};

	/**
	 * Builds and sets the hierarchy to expand in the layers panel
	 * till the selected bay map object.
	 * Also sets the bay's entity id to highlight.
	 * @param bayEntity
	 */
	const handleBayMapSelection = (bayEntity: BayEntity, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: bayEntity.getModelId(),
			entityType: 'bay',
			selectedViaLayers: selectedViaLayers,
		});
		buildHierarchyToExpand.push(objectItems[1].name);

		if (bayEntity.areaId) {
			const areaForSelectedBay = map.getMapLookup().getEntity(bayEntity.areaId, AreaEntity);
			if (!!areaForSelectedBay) {
				buildHierarchyToExpand.push(areaTypeOptions[areaForSelectedBay.areaType]);
				if (areaForSelectedBay.areaType === "AREAAUTONOMOUS") {
					buildHierarchyToExpand.push(areaLocationTypeOptions[areaForSelectedBay.locType]);
				}
			}

			const areaName = (!!areaForSelectedBay && !areaForSelectedBay.isImported ? '[NEW] ' : '') + bayEntity.areaName;

			buildHierarchyToExpand.push(`area_${areaName}`);
		} else { // Handle Undefined bay
			buildHierarchyToExpand.push(areaTypeOptions["AREAAUTONOMOUS"]);
			buildHierarchyToExpand.push(`area_${UNDEFINED_BAYS_AREANAME}`);
		}
		setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
	};

	/**
	 * Builds and sets the hierarchy to expand in the layers panel
	 * till the selected area map object.
	 * Also sets the area's entity id to highlight.
	 * @param areaEntity
	 */
	const handleAreaMapSelection = (areaEntity: AreaEntity, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: areaEntity.getModelId(),
			entityType: 'area',
			selectedViaLayers: selectedViaLayers,
		});
		buildHierarchyToExpand.push(objectItems[1].name); // 'AHS Map'
		buildHierarchyToExpand.push(objectItems[1].children![0].name); // 'Areas'

		buildHierarchyToExpand.push(areaTypeOptions[areaEntity.areaType]);
		if (areaEntity.areaType === "AREAAUTONOMOUS") {
			buildHierarchyToExpand.push(areaLocationTypeOptions[areaEntity.locType]);
		}

		setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
	};

	/**
	 * Builds and sets the hierarchy to expand in the layers panel
	 * till the selected sublink map object.`
	 * Also sets the sublink's entity id to highlight.
	 * @param sublinkEntity
	 */
	const handleSublinkMapSelection = (sublinkEntity: SublinkEntity, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: sublinkEntity.getModelId(),
			entityType: 'sublink',
			selectedViaLayers: selectedViaLayers,
		});
		buildHierarchyToExpand.push(objectItems[1].name); // 'AHS Map'
		buildHierarchyToExpand.push(objectItems[1].children![1].name); // 'Paths'

		const parentLink = objectItems[1]?.children?.[1].children?.find(link => link.entityId === sublinkEntity.linkId);
		if (parentLink && parentLink.entityId) {
			buildHierarchyToExpand.push(layersPanelItemId(parentLink));
			setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
		}
	};

	/**
	 * Builds and sets the hierarchy to expand in the layers panel
	 * till the selected node map object.
	 * Also sets the node's entity id to highlight.
	 * @param nodeEntity
	 */
	const handleNodeMapSelection = (nodeEntity: NodeEntity, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: nodeEntity.getModelId(),
			entityType: 'node',
			selectedViaLayers: selectedViaLayers,
		});
		buildHierarchyToExpand.push(objectItems[1].name); // 'AHS Map'
		buildHierarchyToExpand.push(objectItems[1].children![1].name); // 'Paths'

		const linkForSelectedNode = map.getMapLookup().getLinkByIdNumber(nodeEntity.linkIdNumber);
		const parentLink = objectItems[1]?.children?.[1]
			.children?.find(link => link.entityId === linkForSelectedNode?.id);
		if (!parentLink || !parentLink.entityId) {
			setHierarchyToExpand(buildHierarchyToExpand);
			return;
		}

		buildHierarchyToExpand.push(layersPanelItemId(parentLink));

		/**
		 * Start and end nodes of the newly created link does not have sublink data.
		 * Hence adding the below logic to get the required sublink id to get the 'parentSublink'.
		 */
		let sublinkIdForSelectedNode: string = nodeEntity.sublink?.getModelId() ?? nodeEntity.sublinkId;
		const parentSublink = parentLink.children?.find(sublink => sublink.entityId === sublinkIdForSelectedNode);

		if (parentSublink && parentSublink.entityId) {
			buildHierarchyToExpand.push(layersPanelItemId(parentSublink));
		}

		setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
	};

	const handleFmsMapObjectSelection = (entity: Model, mapObjectType?: MapObjectType, selectedViaLayers?: boolean) => {
		setEntityIdForSelectedMapObject({
			entityId: entity.getModelId(),
			entityType: mapObjectType,
			selectedViaLayers: selectedViaLayers,
		});

		const findInChildren = (entityId: string, items: ILayersPanelItem[] | undefined): boolean => {
			if (!items) {
				return false;
			}
			for (const item of items) {
				if (item.entityId === entityId) {
					buildHierarchyToExpand.unshift(layersPanelItemId(item));
					return true;
				}

				if (findInChildren(entityId, item.children)) {
					buildHierarchyToExpand.unshift(layersPanelItemId(item));
					return true;
				}
			}

			return false;
		};

		// Find the object
		findInChildren(entity.getModelId(), objectItems[0].children);
		buildHierarchyToExpand.unshift(objectItems[0].name);
		setHierarchyToExpand(prev => [...prev, ...buildHierarchyToExpand]);
	};

	/**
	 * Invokes the appropriate handle for expansion in the layers panel based on the type of entity.
	 * @param entity
	 * @param mapObject
	 */
	const handleSelection = (entity: Model | undefined, mapObjectType?: MapObjectType, selectedViaLayers?: boolean) => {
		if (!entity) {
			setEntityIdForSelectedMapObject({ entityId: '' });
			return;
		}

		if (!entity.getModelDisplayName || (typeof entity.getModelDisplayName !== 'function')) {
			// TODO: check if this is still a bug
			// sometimes the type info has been removed from the entity
			console.log('handleSelection: invalid entity');
			return;
		}

		if (entityIdForSelectedMapObject === inactiveEntityId) {
			setInactiveEntityId(() => ({ entityId: '' }));
		} else { // @ts-ignore
			// eslint-disable-next-line no-lonely-if
			if (entityIdForSelectedMapObject === entity.getModelId()) {
				return;
			}
		}

		buildHierarchyToExpand = [];

		switch (entity.getModelDisplayName()) {
			case 'Bay':
				handleBayMapSelection(entity as BayEntity, selectedViaLayers);
				break;
			case 'Area':
				if (mapObjectType !== 'location') {
					handleAreaMapSelection(entity as AreaEntity, selectedViaLayers);
				} else {
					handleFmsMapObjectSelection(entity, mapObjectType, selectedViaLayers);
				}
				break;
			case 'Link':
				setEntityIdForSelectedMapObject(() => ({ 
					entityId: (entity as LinkEntity).getModelId(),
					entityType: mapObjectType,
					selectedViaLayers: selectedViaLayers,
				}));
				break;
			case 'Sublink':
				handleSublinkMapSelection(entity as SublinkEntity, selectedViaLayers);
				break;
			case 'Node':
				handleNodeMapSelection(entity as NodeEntity, selectedViaLayers);
				break;
			case 'Beacon':
			case 'Segment':
			case 'Location':
				handleFmsMapObjectSelection(entity, mapObjectType, selectedViaLayers);
				break;
			default:
				buildHierarchyToExpand = [];
				setEntityIdForSelectedMapObject({ entityId: '' });
				break;
		}
	};

	/**
	 * Determines if the given layers panel item is among the hierarchy to expand.
	 * @param item - ILayersPanelItem
	 */
	const isAmongHierarchyToExpand = (item: ILayersPanelItem): boolean => {
		return hierarchyToExpand.some(x => x === layersPanelItemId(item));
	};

	const handleError = () => {
		const option = !errorToggle === true ? 'activate' : 'deactivate';
		setCustomTag('layers-panel', `${option}-error-filter`);
		setErrorToggle(errorToggleValue => !errorToggleValue);
		performSearch(search.searchTerm, !errorToggle, warningToggle);
	};

	const handleWarning = () => {
		const option = !errorToggle === true ? 'activate' : 'deactivate';
		setCustomTag('layers-panel', `${option}-warning-filter`);
		setWarningToggle(prevWarningToggle => !prevWarningToggle);
		performSearch(search.searchTerm, errorToggle, !warningToggle);
	};

	return (
		<>
			<div className="search-bar">
				<h5>Map Objects</h5>
				<SearchForm
					className="small"
					model={search}
					onChange={value => handleSearchChange(value)}
					clickToClear
					onClickToClear={() => handleSearchChange('')}
				/>
			</div>
			<div className="section-divider" />
			<div className="tree">
				{
					searchState.filteredObjects.length > 0
						? searchState.filteredObjects.map((item, index) => (
							<LayersPanelItem
								objectItem={item}
								forceExpand={searchState.isSearched ? true : isAmongHierarchyToExpand(item)}
								isSearched={searchState.isSearched}
								hierarchyToExpand={hierarchyToExpand}
								itemEntityIdToSetActive={entityIdForSelectedMapObject}
								itemEntityIdToSetInactive={inactiveEntityId}
								map={map}
								key={item.name + index.toString()}
								id={item.name + index.toString()}
								isViewMenuShownInLayersPanel={isViewMenuShownInLayersPanel}
							/>
						)) : <span className="no-results">No search results found</span>
				}
			</div>
			<div className="map-check-errors">
				<div className="label icon-left">
					<div
						className={classNames('error-button-styling', errorToggle ? 'button-styling-active' : '')}
						onClick={handleError}
					>
						{errorCount === 0
						? (
							<>
								<span className="dot-error" />
								<span className="error-layers-panel">
									({errorCount}) Errors
								</span>
							</>
						)
						: (
							<>
								<span className="dot-error error" />
								<span className="error-layers-panel error">
									({errorCount}) Errors
								</span>
							</>
						)}
					</div>
				</div>
				<div className="label icon-right">
					<div
						className={classNames('warning-button-styling', warningToggle ? 'button-styling-active' : '')}
						onClick={handleWarning}
					>
						{warningCount === 0
						? (
							<>
								<span className="dot-error" />
								<span className="error-layers-panel">
									({warningCount}) Warnings
								</span>
							</>
						)
						: (
							<>
								<span className="dot-error warning" />
								<span className="error-layers-panel warning">
									({warningCount}) Warnings
								</span>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
