import MapStateHandler from './MapStateHandler';
import { AreaEntity, MapObjectErrorsEntity } from '../../../../Models/Entities';
import {LeafletMouseEvent} from 'leaflet';
import { PixiCoordinates } from '../Helpers/Coordinates';
import alertToast from '../../../../Util/ToastifyUtils';
import { Area, MapController } from '../../index';
import * as PIXI from 'pixi.js';
import { store } from 'Models/Store';

import * as uuid from 'uuid';
import type { IAreaNodeLocation } from './AreaEditHandler';

import {
	autorun,
	IReactionDisposer,
	observable,
	runInAction,
} from 'mobx';

import AreaValidator, {
	AREA_ERROR_MAXIMUM_NODES,
	AREA_ERROR_MINIMUM_NODES_CLOSE,
	MAXIMUM_AREA_NODES,
} from '../MapValidators/AreaValidator';
import { setCustomTag } from '../Helpers/MapUtils';

export default class AreaToolHandler extends MapStateHandler<never> {
	private newArea: AreaEntity;

	private areaMapObject: Area | undefined;

	@observable
	private areaNodeLocation: IAreaNodeLocation = { northing: undefined, easting: undefined };

	@observable
	private isEditable: boolean = false;

	private autorunDisposer: IReactionDisposer;

	private lastUpdate: number = 0;

	private timeoutId: NodeJS.Timeout | undefined;

	private areaValidator: AreaValidator;

	onInit() {
		this.getController().setCursor('crosshair');
		this.createAreaEntity();

		this.autorunDisposer = autorun(() => {
			this.getEventHandler().emit('onAreaStateChange', this.areaNodeLocation, this.isEditable);
		});
		runInAction(() => {
			this.isEditable = true;
		});
		this.areaValidator = new AreaValidator(this.getController());
	}

	/**
	 * Change cursor depending on whether the user is hovering over area node
	 * @param event
	 */
	onMove(event: LeafletMouseEvent) {
		const debounceMilliseconds = 100;
		const updateLocation = () => runInAction(() => {
			this.lastUpdate = Date.now();
			let _areaNodeLocation = this.getRenderer().getRealWorldCoords(event.latlng);
			const mousePos = this.getRenderer().mousePosition;
			if (_areaNodeLocation.easting !== mousePos.easting || _areaNodeLocation.northing !== mousePos.northing) {
				_areaNodeLocation = mousePos;
			}
			this.areaNodeLocation = _areaNodeLocation;
			if (this.timeoutId !== undefined) {
				clearTimeout(this.timeoutId);
				this.timeoutId = undefined;
			}
		});

		if ((Date.now() - this.lastUpdate) > debounceMilliseconds) {
			updateLocation();
		}
	}

	onDoubleClick(event: LeafletMouseEvent) {
		
		if (this.areaMapObject === undefined) {
			this.areaMapObject = new Area(this.newArea, this.getRenderer(), this.getLookup());
			console.log(`Added object ${this.areaMapObject.getId()}`);
			this.getRenderer().addObject(this.areaMapObject);
		}

		const pixiCoords = this.getRenderer().project(event.latlng);
		const newPoint = new PIXI.Point(pixiCoords.x, pixiCoords.y);
		const points = this.areaMapObject.getPoints();
		let isAddNode = true;
		let isClosing = false;
		if (points.length > 0) {
			if (this.areaMapObject.isClosed()) {
				isAddNode = false;
				console.log('Area already closed');
			} else {
				const firstNode = points[0];
				if (points.length > 3) {
					console.log('Closing area');
					this.deleteAreaNode(); //remove single click created node from 1st click of this dblClick
					newPoint.copyFrom(firstNode);
					isClosing = true;
				} else {
					isAddNode = false;
					alertToast(AREA_ERROR_MINIMUM_NODES_CLOSE, 'error');
				}
			}
		}
		if (isAddNode) {
			if (!isClosing && ((points.length + 1) > MAXIMUM_AREA_NODES)) {
				alertToast(AREA_ERROR_MAXIMUM_NODES, 'error');
				return;
			}
			this.areaMapObject.addPoint(newPoint);
			this.areaMapObject.clearLine();
			this.updateArea();

			// First node is special case
			let originalSelectedPoint: PixiCoordinates;
			if (points.length > 1) {
				if (isClosing) {
					originalSelectedPoint = points[points.length - 3];
				} else {
					originalSelectedPoint = points[points.length - 2];
				}
			} else {
				originalSelectedPoint = newPoint; // TODO: remove??
			}

			const errorMessage = this.areaValidator.validateLocation(event.latlng,
				this.areaMapObject, originalSelectedPoint, isClosing);
			const isValidLocation = errorMessage.length === 0;
			if (!isValidLocation) {
				alertToast(errorMessage, 'error');
				this.deleteAreaNode();
			} else {
				if (this.areaMapObject.getPoints().length === 1) {
					// Show confirm button when first point is placed
					this.getEventHandler().emit('toggleConfirmCreation', true, true);
				}
				if (!isClosing) {
					this.areaMapObject.startLine();
				} else {
					// Enter edit mode
					this.getEventHandler().setMapEventState('edit_area', {
						mapObject: this.areaMapObject, isEditMode: true,
					});
					AreaValidator.checkDynamicAreaSublinkWarnings(this.areaMapObject, this.getController());
				}
			}
		}
		runInAction(() => {
			this.isEditable = false;
		});		
		
	}

	onClick(event: LeafletMouseEvent) {
		if (this.areaMapObject === undefined) {
			this.areaMapObject = new Area(this.newArea, this.getRenderer(), this.getLookup());
			console.log(`Added object ${this.areaMapObject.getId()}`);
			this.getRenderer().addObject(this.areaMapObject);
		}

		const pixiCoords = this.getRenderer().project(event.latlng);
		const newPoint = new PIXI.Point(pixiCoords.x, pixiCoords.y);
		const points = this.areaMapObject.getPoints();
		let isAddNode = true;
		let isClosing = false;
		if (points.length > 0) {
			if (this.areaMapObject.isClosed()) {
				isAddNode = false;
				console.log('Area already closed');
			} else {
				const firstNode = points[0];
				const distance = Math.sqrt((firstNode.x - newPoint.x) ** 2 + (firstNode.y - newPoint.y) ** 2);
				if (distance <= Area.areaNodeRadius) {
					if (points.length > 2) {
						console.log('Closing area');
						newPoint.copyFrom(firstNode);
						isClosing = true;
					} else {
						isAddNode = false;
						alertToast(AREA_ERROR_MINIMUM_NODES_CLOSE, 'error');
					}
				}
			}
		}
		if (isAddNode) {
			if (!isClosing && ((points.length + 1) > MAXIMUM_AREA_NODES)) {
				alertToast(AREA_ERROR_MAXIMUM_NODES, 'error');
				return;
			}
			this.areaMapObject.addPoint(newPoint);
			this.areaMapObject.clearLine();
			this.updateArea();

			// First node is special case
			let originalSelectedPoint: PixiCoordinates;
			if (points.length > 1) {
				if (isClosing) {
					originalSelectedPoint = points[points.length - 3];
				} else {
					originalSelectedPoint = points[points.length - 2];
				}
			} else {
				originalSelectedPoint = newPoint; // TODO: remove??
			}

			const errorMessage = this.areaValidator.validateLocation(event.latlng,
				this.areaMapObject, originalSelectedPoint, isClosing);
			const isValidLocation = errorMessage.length === 0;
			if (!isValidLocation) {
				alertToast(errorMessage, 'error');
				this.deleteAreaNode();
			} else {
				if (this.areaMapObject.getPoints().length === 1) {
					// Show confirm button when first point is placed
					this.getEventHandler().emit('toggleConfirmCreation', true, true);
				}
				if (!isClosing) {
					this.areaMapObject.startLine();
				} else {
					// Enter edit mode
					this.getEventHandler().setMapEventState('edit_area', {
						mapObject: this.areaMapObject, isEditMode: true,
					});
					AreaValidator.checkDynamicAreaSublinkWarnings(this.areaMapObject, this.getController());
				}
			}
		}
		runInAction(() => {
			this.isEditable = false;
		});
	}

	onRequestUpdate() {
		this.updateArea();
		// this.getEventHandler().emit('onMapObjectUpdate', this.newArea);
	}

	updateArea() {
		if (!!this.areaMapObject) {
			runInAction(() => {
				this.newArea.perimeterCount = this.areaMapObject?.getPointsWithoutClose().length ?? undefined as any;
			});
			const renderer = this.getRenderer();
			renderer.markObjectToRerender(this.areaMapObject.getId());
			renderer.rerender();
		}
	}

	deleteAreaNode() {
		if (!!this.areaMapObject) {
			const points = this.areaMapObject.getPoints();
			const numOfPoints = points.length;
			if (numOfPoints > 0) {
				const isLastPoint = numOfPoints === 1;
				this.areaMapObject.deletePreviousPoint();
				this.areaMapObject.clearLine();
				if (isLastPoint) {
					// Remove confirm button when no points are placed
					this.getEventHandler().emit('toggleConfirmCreation', false);
					runInAction(() => {
						this.isEditable = true;
					});
				} else {
					setCustomTag('map-interface', 'delete-an-area-node (draw mode)');
					this.areaMapObject.startLine();
				}
				this.updateArea();
			}
		}
	}

	/**
	 * Delete latest node when Delete or Backspace is pressed
	 * @param event
	 */
	onKeyPress(event: KeyboardEvent) {
		if (['Delete', 'Backspace'].includes(event.key)) {
			this.deleteAreaNode();
		}
	}

	onEscapePressed(event: KeyboardEvent) {
		if (this.areaMapObject !== undefined && !this.areaMapObject.isClosed()) {
			// Press escape when object is being drawn
			runInAction(() => {
				this.isEditable = true;
			});
			this.updateArea();
			this.getController().removeMapObject(this.areaMapObject.getEntity(), this.areaMapObject);
			this.areaMapObject = undefined;
			this.createAreaEntity();
			this.getEventHandler().emit('toggleConfirmCreation', false);
		} else {
			// Press escape when no object is being drawn goes to selector tool
			this.getEventHandler().setActiveTool('selector');
		}
	}

	dispose() {
		if (!!this.areaMapObject && !this.areaMapObject.isClosed()) {
			this.getController().removeMapObject(this.newArea, this.areaMapObject);
			this.autorunDisposer();
		}
		// this.getEventHandler().emit('onMapObjectDelete', this.newArea);
		this.getController().setDefaultCursor();
		// Remove confirm button when exising this tool (if going to Edit, it will be re-added)
		this.getEventHandler().emit('toggleConfirmCreation', false);
	}

	/**
	 * Create new bay. Using clientId (as entity id is not yet set)
	 */
	private createAreaEntity() {
		const id = uuid.v4();
		this.newArea = new AreaEntity({
			id: id,
			areaName: '',
			areaVersion: '',
			areaId: undefined,
			perimeterCount: undefined,
			areaCreator: '',
			areaType: 'AREALOCKOUT',
			locType: 'INVALID',
			polygon: '',
			entranceNodeId: undefined,
			exitNodeId: undefined,
			state: 'NEW_OBJECT',
		});

		this.newArea.areaCreator = store.email?.split('@')[0] ?? '';
		// show in properties panel
		this.getEventHandler().emit('onPropertiesPanel', 'area', this.newArea);
	}
}
