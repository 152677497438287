/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsMineSiteEntity from 'Models/Security/Acl/VisitorsMineSiteEntity';
import MineUserMineSiteEntity from 'Models/Security/Acl/MineUserMineSiteEntity';
import HitachiAdminMineSiteEntity from 'Models/Security/Acl/HitachiAdminMineSiteEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMineSiteEntityAttributes extends IModelAttributes {
	name: string;
	logoId: string;
	logo: Blob;
	timezone: string;

	ahsMineAdminss: Array<
		| Models.MineUserEntity
		| Models.IMineUserEntityAttributes
	>;
	mapss: Array<
		| Models.MapEntity
		| Models.IMapEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	isTestSite: boolean;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MineSiteEntity', 'Mine site')
// % protected region % [Customise your entity metadata here] end
export default class MineSiteEntity extends Model
	implements IMineSiteEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMineSiteEntity(),
		new MineUserMineSiteEntity(),
		new HitachiAdminMineSiteEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * A common name for the mine site.
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Logo'] off begin
	/**
	 * The logo of the mine.
	 */
	@observable
	@attribute({ file: 'logo' })
	@CRUD({
		name: 'Logo',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'logo',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="The logo of the mine." style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public logoId: string;

	@observable
	public logo: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Logo'] end

	// % protected region % [Modify props to the crud options here for attribute 'Timezone'] on begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Timezone',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public timezone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Timezone'] end

	/**
	 * A mine site has many admins that manage the mine.
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'AHS Mine Admins'] off begin
		name: 'AHS Mine Adminss',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.MineUserEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'ahsMineAdminss',
			oppositeEntity: () => Models.MineUserEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'AHS Mine Admins'] end
	})
	public ahsMineAdminss: Models.MineUserEntity[] = [];

	/**
	 * A mine site has many maps that have been built for it.
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Maps'] off begin
		name: 'Mapss',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.MapEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapss',
			oppositeEntity: () => Models.MapEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Maps'] end
	})
	public mapss: Models.MapEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute()
	@CRUD({
		name: 'Enable evaluation mode?',
		displayType: 'checkbox',
		order: 30,
	})
	public isTestSite: boolean;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMineSiteEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMineSiteEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.logo !== undefined) {
				this.logo = attributes.logo;
			}
			if (attributes.logoId !== undefined) {
				this.logoId = attributes.logoId;
			}
			if (attributes.timezone !== undefined) {
				this.timezone = attributes.timezone;
			}
			if (attributes.ahsMineAdminss !== undefined && Array.isArray(attributes.ahsMineAdminss)) {
				for (const model of attributes.ahsMineAdminss) {
					if (model instanceof Models.MineUserEntity) {
						this.ahsMineAdminss.push(model);
					} else {
						this.ahsMineAdminss.push(new Models.MineUserEntity(model));
					}
				}
			}
			if (attributes.mapss !== undefined && Array.isArray(attributes.mapss)) {
				for (const model of attributes.mapss) {
					if (model instanceof Models.MapEntity) {
						this.mapss.push(model);
					} else {
						this.mapss.push(new Models.MapEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.isTestSite !== undefined) {
				this.isTestSite = attributes.isTestSite;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		mapss {
			${Models.MapEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			mapss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'mapss',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MineSiteEntity.prototype, 'created');
CRUD(modifiedAttr)(MineSiteEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
