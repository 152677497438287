import {
	AreaEntity,
	BayEntity,
	MapToolParamEntity,
} from '../../../../Models/Entities';
import {
	Bay, MapController,
} from 'Views/MapComponents';
import { getJsonObject } from '../Helpers/GeoJSON';
import axios, { AxiosError } from 'axios';
import { SERVER_URL } from '../../../../Constants';
import {
	action,
	runInAction,
} from 'mobx';
import { realWorldCoordinates, RealWorldCoordinates } from '../Helpers/Coordinates';
import MapValidator from './MapValidator';
import BayEditHandler from '../MapStateHandlers/BayEditHandler';
import { BayTypeParams } from '../MapStateHandlers/BayToolHandler';

export const BAY_AREA_MISMATCH_ERROR = 'Bay cannot be moved from original area.';
export const BAY_LOCATION_ERROR = 'This operation is not allowed. The bay must be located within an Autonomous Area';
export const BAY_DISTANCE_ERROR = (distance: string | number) => `This operation is not allowed. The bay is less than ${distance}m away from another bay.`;
// not sure why two similar errors exist
export const BAY_DISTANCE_ERROR_2 = (distance: string | number) => `The distance should be greater than or equal to ${distance}m.`;
// Invalid location selected for the bay. Please select a location within an Autonomous Area boundary.
export const BAY_IMPORTED_INVALID_SHIFT = 'This operation is not allowed. Imported bays cannot be shifted.';
export const BAY_AHS_BOUNDARY_ERROR = 'This operation is not allowed. The bay cannot be outside the allowed boundary for AHS objects';
export const PARKING_LOC_BAY_TYPE_OPTIONS = {
	PARKING: 'Parking',
	FUELLING: 'Fuelling',
};

export const DUMP_LOC_BAY_TYPE_OPTIONS = 					{
	DUMPPADDOCK: 'Paddock Dumping',
	DUMPOVEREDGE: 'Over The Edge Dumping',
};

export const STOCKPILE_LOC_BAY_TYPE_OPTIONS = {
	DUMPPADDOCK: 'Paddock Dumping',
	LOADING: 'Loading',
};

export const BAY_IMPORTED = 'IMPORTED';

export default class BayValidator {
	/**
	 * Validate bays using the serverside checks.
	 */
	public static async checkBayErrors(
		newBay: BayEntity,
		mapParams: MapToolParamEntity,
		map: MapController,
		dispose?: boolean,
		shiftBay?: boolean,
	): Promise<BayEntity> {
		// Create dummy bay entity and associate area with it for the purpose of validation
		// Not using the actual entity because this is to just validate unconfirmed changes
		const tempBayEntity = new BayEntity(newBay);
		const { coordinates } = getJsonObject(newBay.bayLocation);
		const bayCoordinates = realWorldCoordinates(coordinates[1], coordinates[0]);
		const bayArea = BayValidator.getBayArea(bayCoordinates, map);
		if (!!bayArea) {
			// const area = map.getMapLookup().getEntity(tempBayEntity.areaId, AreaEntity);
			// Ensure that area is validated with most up-to-date info
			tempBayEntity.areaId = bayArea.id;
			tempBayEntity.area = bayArea;
		} else {
			// TODO: Validation should fail with MapErrorFlags.BayWrongLocationError
		}

		// run the bay to bay distance check here, returns false if error
		const bayDistanceError = BayValidator.checkBayDistance(newBay, mapParams, map);

		// converting bay & area entity to match the serverside requirements
		const bayToJson = tempBayEntity.toJSON({ bayLocation: {}, area: {} });
		const areaToJson = bayArea!.toJSON({ polygon: {} });

		const importVersionId = map.getImportVersion().id;

		// calling server side validation for bays
		await axios.post(`${SERVER_URL}/api/entity/BayEntity/validateBayErrors`, {
			bay: bayToJson,
			mapParams: mapParams,
			importVersionId: importVersionId,
			bayArea: areaToJson,
		}).then(async ({ data }) => {
			const errorMessages = Array.from<string>(data.bayErrors);
			const warningMessages = Array.from<string>(data.bayWarnings);
			console.log(`validateBayErrors (from server): ${errorMessages.join(',')}`);
			if (!bayDistanceError) {
				console.log(`validateBayErrors: bayDistanceError`);
				warningMessages.push(`The bays are less than ${mapParams.bayToBay}m apart.`);
			}


			map.getEventHandler().emit('onMapObjectUpdate', newBay);

			if (shiftBay && errorMessages.length > 0) {
				console.log(`validateBayErrors: shift bay and return`);
				return newBay;
			}
			
			// IMPORTANT: on a new bay, the errors will not be saved until onTrackCreate is called (since the bay doesn't exist in DB)
			// There is a special case in the onTrackCreate where entity.saveErrors() is called for bays
			// On existing bays the errors are saved below.

			const entity = map.getMapLookup().getEntity(newBay.getModelId(), BayEntity);
			const isTrackingDisabled = false; //map.getTracker().getIsBayTrackingDisabled(); // TODO: refactor
			if (!!entity && !isTrackingDisabled) {
				// Do not attempt to save if either entity doesn't exist OR tracking is disabled
				console.log(`checkBayErrors: found entity in lookup`);
				const success = map.getMapLookup().resetAndAddNewErrors(newBay.getModelId(), BayEntity, errorMessages);
				map.getMapLookup().resetAndAddNewWarnings(newBay.getModelId(), BayEntity, warningMessages);
				console.log(`validateBayErrors: resetAndAddNewErrors success. saving`);
			} else {
				newBay.addErrors(errorMessages);
				newBay.addWarnings(warningMessages);
				console.log(`checkBayErrors: Not saving because entity = ${!!entity} && isTrackingDisabled: ${isTrackingDisabled}`);
			}

			// re-render error and warning information
			console.log(`validateBayErrors: re-render error and warning info`);
			const bayMapObjectId = map.getMapLookup().getMapObjectId(newBay.getModelId(), 'bay');
			const bayMapObject = map.getMapRenderer().getObjectById(bayMapObjectId);
			if(!bayMapObject)
			{
				console.warn("validateBayErrors: bay map object not found");
				return newBay;
			}
			map.getMapRenderer().markObjectToRerender(bayMapObjectId);
			const hasErrors = newBay.mapObjectErrorss.length > 0;
			bayMapObject.isError = hasErrors;
			console.log(`hasErrors: ${hasErrors} errorList: ${errorMessages.join(',')}`);
			const hasWarnings = newBay.mapObjectWarningss.length > 0;
			bayMapObject.isWarning = hasWarnings;
			bayMapObject?.setTooltipDisplay(true, hasErrors, hasWarnings);
			BayEditHandler._isDisableConfirm(newBay, map.getEventHandler());
			map.getEventHandler().emit('onMapObjectUpdate', newBay, newBay.id);
			return newBay;
		}).catch((err: AxiosError) => {
			console.log('error in axios call ', err);
		});
		return newBay;
	}

	public static validateInsideOriginalArea(coords: RealWorldCoordinates, bay: BayEntity, controller: MapController) {
		return this._validateInsideOriginalArea(this.getBayArea(coords, controller), bay);
	}
	
	public static _validateInsideOriginalArea(bayArea: AreaEntity | undefined, bay: BayEntity) {
		let err: string | undefined;
		if (!bayArea) {
			err = BAY_LOCATION_ERROR;
		} else if (bayArea.id !== bay.areaId) {
			err = BAY_AREA_MISMATCH_ERROR;
		}
		return err;
	}

	/**
	 * Validate bays for the bay to bay distance.
	 */
	public static checkBayDistance(newBay: BayEntity, mapParams: MapToolParamEntity, map: MapController): boolean {
		const { coordinates } = getJsonObject(newBay.bayLocation);
		const newBayEasting = coordinates[0] as any as number;
		const newBayNorthing = coordinates[1] as any as number;

		// calculate the errors count before and after to ensure the total number of errors to be added to the layers panel
		let result = true;
		if (!!newBay.areaId) {
			// get all the bays in an area and run validations on it to check if bay to bay distance is affected
			const baysList = map.getMapLookup().getBaysByAreaId(newBay.areaId);
			if (!!baysList) {
				for (let i = 0; i < baysList.length; i++) {
					if (baysList[i].id !== newBay.id) {
						let easting;
						let northing;
						// @ts-ignore
						if (typeof baysList[i].bayLocation === 'object') {
							// @ts-ignore
							easting = baysList[i].bayLocation.coordinates[0];
							// @ts-ignore
							northing = baysList[i].bayLocation.coordinates[1];
						} else {
							// parsing to access the coordinates in the form of an array
							const { coordinates } = getJsonObject(baysList[i].bayLocation);
							easting = coordinates[0] as any as number;
							northing = coordinates[1] as any as number;
						}

						const x = northing - newBayNorthing;
						const y = easting - newBayEasting;
						const distance = Math.sqrt(x * x + y * y);

						if (Number(distance.toFixed(2)) < mapParams.bayToBay) {
							result = false;
						}
					}
				}
			}
		}
		console.log(`checkBayDistance: result = ${result} (false is error)`)
		return result;
	}

	/** 
	 * Update bay type based on properties. Set correct baytype, spotdir, and callingtype
	 * when creating a new bay.
	 */
	@action
	public static setBayTypeBeforeConfirm(bay: BayEntity, map: MapController) {
		if (!bay.areaId) {
			return false;
		}
		const area = map.getMapLookup().getEntity(bay.areaId, AreaEntity);
		if (!area) {
			return false;
		}
		const loctype = area.locType;
		if (!loctype) {
			return false;
		}

		if (loctype === 'PARKING') {
			//only default to 'DRIVETHROUGH' if no selection has been made (currently will not happen there is no empty option in drop down)
			if (!bay.spotDir) { 
				bay.spotDir = 'DRIVETHROUGH';
			}
			bay.callingType = 'SINGLEAHT';
			// default set to parking instead of paddock dumping. If it's set to fuelling, it won't be overridden by parking
			if (bay.bayType !== 'PARKING') {
				if (bay.bayType !== 'FUELLING') {
					bay.bayType = 'PARKING';
				}
			}
		}
		if (loctype === 'DIG') {
			bay.spotDir = 'BACKIN';
			if (bay.bayType !== 'LOADING') {
				bay.bayType = 'LOADING';
				bay.callingType = 'INFINITE';
			}
		}
		if (loctype === 'CRUSHER') {
			bay.spotDir = 'BACKIN';
			bay.callingType = 'INFINITE';
			bay.bayType = 'DUMPCRUSHER';
		}
		if (loctype === 'DUMP') {
			const bays = map.getMapLookup().getBaysByAreaId(area.id);
			if (bay.bayType === 'DUMPOVEREDGE') {
				bay.spotDir = 'BACKIN';
			}
			bay.callingType = 'SINGLEAHT';
			if (bays === undefined || bays.length === 0) {
				// default to paddock dumping if selection is not DUMPOVEREDGE or DUMPPADDOCK
				if (!['DUMPOVEREDGE', 'DUMPPADDOCK'].includes(bay.bayType)) { 
					bay.bayType = 'DUMPPADDOCK';
				}
			} else {
				// If the number of bays in the area is larger than zero (> 0),
				// the Bay type will be read-only to the same type as the first
				bay.bayType = bays[0].bayType;
			}
		}
		if (loctype === 'STOCKPILE') {
			bay.bayType = 'DUMPPADDOCK';
			bay.spotDir = 'BACKIN';
			bay.callingType = 'INFINITE';
		}

		return true;
	}

	/** 
	 * Get bay type params when creating a new bay.
	 */
	@action
	public static getBayTypeParamsBeforeConfirm(bay: BayEntity, map: MapController, isNew: boolean) {
		if (!isNew || !bay.areaId) {
			return;
		}
		const area = map.getMapLookup().getEntity(bay.areaId, AreaEntity);
		if (!area) {
			return;
		}
		const loctype = area.locType;
		if (!loctype) {
			return;
		}

		const bayTypeParams: BayTypeParams = {
			isReadOnly: false,
		};

		if (loctype === 'PARKING') {
			const isBayTypeReadonly = false;
			bayTypeParams.isReadOnly = isBayTypeReadonly;
			bayTypeParams.bayTypeOptions = PARKING_LOC_BAY_TYPE_OPTIONS;
		}
		if (loctype === 'CRUSHER' || loctype === 'DIG') {
			const isBayTypeReadonly = true;
			bayTypeParams.isReadOnly = isBayTypeReadonly;
		}
		if (loctype === 'DUMP') {
			const bays = map.getMapLookup().getBaysByAreaId(area.id);
			let isBayTypeReadonly = false;
			if (bays !== undefined && bays.length > 0) {
				// If the number of bays in the area is larger than zero (> 0),
				// the Bay type will be read-only to the same type as the first
				isBayTypeReadonly = true;
			}
			bayTypeParams.isReadOnly = isBayTypeReadonly;
			bayTypeParams.bayTypeOptions = DUMP_LOC_BAY_TYPE_OPTIONS;
		}
		if (loctype === 'STOCKPILE') {
			const isBayTypeReadonly = false;
			bayTypeParams.isReadOnly = isBayTypeReadonly;
			bayTypeParams.bayTypeOptions = STOCKPILE_LOC_BAY_TYPE_OPTIONS;
		}

		return bayTypeParams;
	}

	// TODO: this should really be somewhere else, but for legacy reasons it's here.
	public static updateBayArea(bay: BayEntity, area: AreaEntity, controller: MapController) {
		controller.getMapLookup().updateEntity(bay, (entity: BayEntity) => {
			runInAction(() => {
				entity.areaId = area.id;
				entity.areaName = area.areaName;
			});
			return entity;
		});
	}

	/**
	 * Returns the area of the current bay
	 * @param coords - coordinates of the bay
	 * @param controller - controller to get the map renderer
	 */
	public static getBayArea = (coords: RealWorldCoordinates, controller: MapController): AreaEntity | undefined => {
		return MapValidator.getAreaAtCoords(coords, controller)?.area;
	}

	// TODO: expand this method to full bay validation
	public static validateBaysInMapBounds (map: MapController) {
		const bayEntities = map.getMapLookup().getAllEntities(BayEntity);
		bayEntities.forEach(b => {
			this.validateBayInMapBounds(b, map);
		});
		console.log("validateBaysInMapBounds is finished.");
	}

	public static validateBayInMapBounds (bayEntity: BayEntity, map: MapController) {
		const lookup = map.getMapLookup();
		const bayMapObject = lookup.getMapObjectByEntity(bayEntity, 'bay') as Bay;
		if (!bayMapObject) {
			return;
		}
		const renderer = map.getMapRenderer();
		const _bayCenter = renderer.unproject(bayMapObject.getBayPoints().center);
		const isInMapBound = renderer.isPointInMapBounds(renderer.getRealWorldCoords(_bayCenter));
		const errorMessage = `Bay_${bayEntity.bayId} is outside the allowed boundary for AHS objects`;
		if (!isInMapBound) {
			lookup.addObjectError(bayEntity.id, BayEntity, errorMessage);			
		} else {
			lookup.removeObjectError(bayEntity.id, BayEntity, errorMessage);
		}

		// Check entity error and warning count and render error or warning styling
		const hasError = bayEntity.getErrorCount() > 0;
		const hasWarning = bayEntity.getWarningCount() > 0;
		MapValidator.setMapObjectTooltipErrorWarning(bayMapObject, hasError, hasWarning);
		// Update the label in the layers panel
		map.getEventHandler().emit('onMapObjectUpdate', bayEntity, bayEntity.id);
	}
	
	public static validateBayCoordsInMapBounds (coordinates: RealWorldCoordinates, map: MapController) {
		if (map.getMapRenderer().isPointInMapBounds(coordinates)) {
			return true;
		}
		return false;
	}

	public static validateBaysDistance (map: MapController) {
		const bayEntities = map.getMapLookup().getAllEntities(BayEntity);
		bayEntities.forEach(b => {
			this.validateBayDistance(b, map);
		});
		console.log("validateBaysDistance is finished.");
	}

	public static validateBayDistance (bayEntity: BayEntity, map: MapController) {
		const lookup = map.getMapLookup();
		const bayMapObject = lookup.getMapObjectByEntity(bayEntity, 'bay') as Bay;
		const mapParams = map.getImportVersion().maptoolparam;
		if (!bayMapObject || !mapParams) {
			return;
		}
		const bayDistanceError = this.checkBayDistance(bayEntity, mapParams, map);
		const warningMessage = `The bays are less than ${mapParams.bayToBay}m apart.`;
		if (!bayDistanceError) {			
			lookup.addObjectWarning(bayEntity.id, BayEntity, warningMessage);			
		} else {
			lookup.removeObjectWarning(bayEntity.id, BayEntity, warningMessage);
		}

		// Check entity error and warning count and render error or warning styling
		const hasError = bayEntity.getErrorCount() > 0;
		const hasWarning = bayEntity.getWarningCount() > 0;
		MapValidator.setMapObjectTooltipErrorWarning(bayMapObject, hasError, hasWarning);
		// Update the label in the layers panel
		map.getEventHandler().emit('onMapObjectUpdate', bayEntity, bayEntity.id);
	}
}
