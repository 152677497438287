import * as React from 'react';
import {useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import Toolbar from './MapToolbar/Toolbar';
import {ImportVersionEntity, NodeEntity} from 'Models/Entities';
import PropertiesPanel from './MapProperties/PropertiesSidePanel';
import displayComingSoonToast from '../../Util/ComingSoon';
import MapController from './Map/MapController';
import If from '../Components/If/If';
import MenuBar, {MenuItemAction} from './MapToolbar/MenuBar';
import {PublishAndExportModal} from 'Views/Components/Shared/FileExportModal';
import {LayersSidePanel} from './LayersPanel/LayersPanel';
import RightClickContextMenu from './RightClickContextMenu';
import {lowerCaseFirst} from 'Util/StringUtils';
import Link from './Map/MapObjects/Link/Link';
import DynamicScaleObjectHelper from './Map/MapStateHandlerHelpers/DynamicScaleObjectHelper';
import {validateFullMap} from './Map/Helpers/FullMapValidation';
import MouseCursorCoords from './MouseCursorCoords';
import {MapParametersModal} from './MapParametersModal';
import {RenderArchiveModal} from 'Views/Components/Shared/ArchiveModal';
import {runInAction} from "mobx";
import {store} from "../../Models/Store";
import {IWhereCondition} from "../Components/ModelCollection/ModelQuery";
import {importNotArchivedCondition} from "./AllImportsForMap";
import AreaImportModal from "../Components/Shared/UploadModals/AreaImportModal";
import CurrentMapImportModal from "../Components/Shared/UploadModals/CurrentMapImportModal";
import TerrainImportModal from "../Components/Shared/UploadModals/TerrainImportModal";
import BackgroundImportModal from "../Components/Shared/UploadModals/BackgroundImageImportModal";

export interface IMenuShownStatus {
	drivingArea: boolean;
    node: boolean;
	sublink: boolean;
	signalSet: boolean;
}

export const initialMenuShownStatus: IMenuShownStatus = {
	drivingArea: true,
	node: true,
	sublink: true,
	signalSet: false,
}

export interface IEditMapProps {
    version: ImportVersionEntity;
}

/**
 * Parent component of all components of the edit map page.
 * It initialises MapController and mounts react components such as toolbar, properties panel and objects panel
 * Sets the interactivity mode (mapMode) via the Toolbar onItemSelect prop
 * @param props ImportVersionEntity
 */
export function EditMap(props: IEditMapProps) {
	const { version } = props;
	const [map, setMap] = useState<MapController | undefined>(undefined);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isTerrain, setIsTerrain] = useState(false);
	const [isBackground, setIsBackground] = useState(false);
	const [isImportArea, setIsImportArea] = useState(false);
	const [isImportMap, setIsImportMap] = useState(false);
	const [isPublishAndExport, setIsPublishAndExport] = useState(false);
	const [isMapParameters, setIsMapParameters] = useState(false);
	const [isMapArchiving, setIsMapArchiving] = useState(false);
	// TODO: refactor. No need the local isViewMenuShown. Use the global isViewMenuShown in MapStore
	let isViewMenuShown = { ...initialMenuShownStatus };

	const closeModal = () => {
		setIsOpenModal(false);
	};

	const getTypes = (viewType: string): (string | undefined) => {
		let type;
		switch (viewType) {
			case 'driving_area':
				type = "DrivingAreaEntity";
				break;
			case 'node':
				type = "NodeEntity";
				break;
			case 'sublink':
				type = "SublinkEntity";
				break;
			case 'signal':
				type = "SignalSetEntity";
				break;
			default:
		}

		return type;
	};

	const setHiddenStatus = (status: boolean, viewType: string) => {
		if (!map) {
			return;
		}		
		const type = getTypes(viewType);
		const lookup = map.getMapLookup();
		const renderer = map.getMapRenderer();

		/**
		 * Set shown/hidden status of sublink/node/signal are complicated because it relates to setting from the View Menu, the layers panel visible icon, and selecting/deselecting an entity
		 * Drivable area is NOT affected by the layers panel.
		 * 
		 * Affected by the layers panel:
		 * 1) Node 2) Sublink: which are directly related to visible icons. Emit onToggleItemsFromViewMenu
		 * 3) Signal: special case. Custom code as below and in LayersPanelItem
		 */

		// If type is not undefined, it has actual entity e.g. Drivable area has DrivingAreaEntity.
		if (!!type) {
			let key = lowerCaseFirst(type).replace('Entity', '');
			isViewMenuShown = {...isViewMenuShown, ...{[key]: status}};
			lookup.setIsViewMenuShown(isViewMenuShown);		

			if(viewType === 'node') {
				map?.getEventHandler().emit('onToggleItemsFromViewMenu', 'node', isViewMenuShown, true);
				return;
			}

			if(viewType === 'sublink') {
				map?.getEventHandler().emit('onToggleItemsFromViewMenu', 'sublink', isViewMenuShown, true);
				return;
			}
			
			map.getEventHandler().emit('onToggleItemsFromViewMenu', 'driving_area', isViewMenuShown, false); // TODO: this line may be able to be deleted.
			const entities = lookup.getAllEntities(type);
			entities?.forEach(e => {
				const mapObjectId = lookup.getMapObjectId(e.id, viewType);
				const mapObject = renderer.getObjectById(mapObjectId);
				if (mapObject !== undefined) {
					if (viewType === 'driving_area') {
						mapObject.displayGraphic(status);
					}

					// When signal view menu is shown, don't care about a Link status in layers panel
					// and selected or de-selected link on the map
					if (viewType === 'signal' && isViewMenuShown.signalSet) {
						mapObject.displayGraphic(status); // true
					}
					// When signal view menu is hidden, if a Link status in layers panel is shown and
					// the link is selected (signal should still be shown)
					if (viewType === 'signal' && !isViewMenuShown.signalSet) {
						const linkMapObject = mapObject.getParent()?.getChildren()?.find(item => item.getType() === 'link') as Link;
						const linkEntityId = linkMapObject?.getLinkEntity().id;
						const isLinkShownInLayersPanel = lookup.getLinkLayersPanelShownStatus(linkEntityId);
						if (linkMapObject?.isHighlighted && (isLinkShownInLayersPanel ?? true)) {
							mapObject.displayGraphic(true);
						} else {
							mapObject.displayGraphic(status); // false
						}
					}
				}
			});
			renderer.rerender();
			
		} else { // Don't have an entity.
			if (viewType === 'backgroundImage') {
				renderer.setBackgroundImageOpacity(status ? 1 : 0);
			} else if (viewType === 'connectivityEndPoints') {
				DynamicScaleObjectHelper.setConnectivityEndpointView(status, map);
			} else if (viewType === 'dynamicConnections') {
				DynamicScaleObjectHelper.setDynamicConnectionsView(status, map);
			} else if (viewType === 'speedLimits') {
				DynamicScaleObjectHelper.setSpeedLimitsView(status, map);
			} else if (viewType === 'labels') {
				// TODO: in future show/hide label tickets, each tooltip of different subtype will have its own class
				// Classes to be shown/hidden will replace leaflet-tooltip in classList
				lookup.setLabelViewStatus({
					allLabels: status,
				});
				renderer.updateMapLabels();
			}
		}		
	};

	const processMenuAction = (action: MenuItemAction) => {
		switch (action) {
			case MenuItemAction.ImportMap:
				setIsImportMap(true);
				break;
			case MenuItemAction.ImportAreas:
				setIsImportArea(true);
				break;
			case MenuItemAction.ImportTerrain:
				setIsTerrain(true);
				break;
			case MenuItemAction.ImportBackground:
				setIsBackground(true);
				break;
			case MenuItemAction.PublishAndExport:
				setIsPublishAndExport(true);
				break;
			case MenuItemAction.ArchiveMap:
				setIsMapArchiving(true);
				break;
			case MenuItemAction.MapParameters:
				setIsMapParameters(true);
				break;
		}
		if (action !== MenuItemAction.ImportMap) {
			setIsImportMap(false);
		}
		if (action !== MenuItemAction.ImportAreas) {
			setIsImportArea(false);
		}
		if (action !== MenuItemAction.ImportTerrain) {
			setIsTerrain(false);
		}
		if (action !== MenuItemAction.ImportBackground) {
			setIsBackground(false);
		}
		if (action !== MenuItemAction.PublishAndExport) {
			setIsPublishAndExport(false);
		}
		if (action !== MenuItemAction.ArchiveMap) {
			setIsMapArchiving(false);
		}
		if (action !== MenuItemAction.MapParameters) {
			setIsMapParameters(false);
		}

		setIsOpenModal(true);
	};

	useEffect(() => {
		// Create the map and mount it to the dom
		const newMap = new MapController(version);

		newMap.mountMap();
		// newMap.startAutoSaveInterval();

		setMap(newMap);
		const renderer = newMap.getMapRenderer(); 

		// Add links here to prevent render loop and memory issues
		// NOTE: moved back to original location after bugfixes
		// AddLinks is the most expensive map loading operation
		// console.time('AddLinks');
		// // newMap.getImportVersion().linkss = [];
		// newMap.getImportVersion().linkss.forEach(link => {
		// 	renderer.addObject(new Path(link, renderer, newMap.getMapLookup()), true);
		// });
		// console.timeEnd('AddLinks');
		// console.time('ReRenderLinks');
		// // renderer.rerender();
		// console.timeEnd('ReRenderLinks');

		/* Perform any cleanup logic if needed when the component is being unmounted */
		return () => {
			// newMap.resetAutoSaveInterval();
			newMap.unmountMap();

			// For now just remove the reference to the map, allowing it to be cleaned up by the gc
			// NOTE: Probably would happen automatically when the component is unmounted
			setMap(undefined);
		};
	}, []);

	useEffect(() => {
		if (!!map) {
			const showErrorsWarnings = false;
			validateFullMap(map, showErrorsWarnings);
		}
	}, [map]);

	/**
	 * Archives the current map import version
	 */
	const archiveImport = async () => {

		if (!version) {
			return;
		}

		// Set archived true for the selected import version
		version.archived = true;
		
		await version.updateWhere(
			undefined,
			['archived'],
			[version.id]
		);
		
		const conditionArgs: Array<Array<IWhereCondition<ImportVersionEntity>>> = [
			[
				{ path: 'mapId', comparison: 'equal', value: version.mapId },
			],
			importNotArchivedCondition,
		];
		
		const allImportVersionsCount: { data: number } = await  ImportVersionEntity.fetchCountOnly<ImportVersionEntity>({ args: conditionArgs, orderBy: [{ path: 'created', descending: true }] });
		
		if (allImportVersionsCount.data === 0) {
			store.routerHistory.push(`/maps`);
		} else {
			store.routerHistory.push(`/maps/${version.mapId}`);
		}
	}
	
	return (
		<div id="leaflet-container" className="map-edit-page">
			{!map ? undefined
				: (
					<div className="toolbars">
						<MenuBar
							importVersion={version}
							onMenuItemClick={(action: MenuItemAction) => processMenuAction(action)}
							map={map}
							setHiddenStatus={(status: boolean, viewType: string) => setHiddenStatus(status, viewType)}
						/>
						<Toolbar
							onItemSelect={item => {
								// TODO: after arc is done, the following displayComingSoonToast can be removed
								if (!['clothoid', 'selector', 'connection', 'bay', 'area', 'undo', 'redo', 'ruler', 'path']
									.includes(item)) {
									displayComingSoonToast();
								}
								if (['undo', 'redo'].includes(item)) {
									const action = item === 'redo' ? 'redo' : 'undo';
									map.getEventHandler().emit(action);
								} else {
									map.getEventHandler().setMapEventState(item);
								}
							}}
							map={map}
							currentZoomLevel={map.getLeafletMap().getZoom()}
						/>
					</div>
				)}

			{map === undefined || version?.backgroundImage === undefined || version.maptoolparam === undefined
				? undefined : (
					<PropertiesPanel
						map={map}
						backgroundEntity={version.backgroundImage}
						mapToolParamEntity={version.maptoolparam}
					/>
				)}

			{map === undefined || version?.backgroundImage === undefined
				? undefined : <LayersSidePanel map={map}/>}

			{map === undefined || version?.backgroundImage === undefined
				? undefined : <MouseCursorCoords map={map} />}

			{map === undefined || version?.backgroundImage === undefined || version.maptoolparam === undefined
				? undefined : <RightClickContextMenu map={map} />}

			<If condition={isOpenModal && isImportArea}>
				<AreaImportModal
					title="Import AHS Current Area Data"
					importVersion={version}
					onCloseModal={closeModal}
					map={map}
				/>
			</If>
			<If condition={isOpenModal && isImportMap}>
				<CurrentMapImportModal
					title="Import AHS Current Map Data"
					importVersion={version}
					onCloseModal={closeModal}
					map={map}
				/>
			</If>
			<If condition={isOpenModal && isTerrain}>
				<TerrainImportModal
					title="Import Drivable Area Data"
					importVersion={version}
					onCloseModal={closeModal}
				/>
			</If>
			<If condition={isOpenModal && isBackground}>
				<BackgroundImportModal
					title="Import Background Image Data"
					importVersion={version}
					onCloseModal={closeModal}
				/>
			</If>
			<If condition={isOpenModal && isPublishAndExport}>
				<PublishAndExportModal
					title="Select data for export"
					importVersion={version}
					mapController={map}
					onCloseModal={closeModal}
				/>
			</If>
			<If condition={isOpenModal && isMapParameters}>
				<MapParametersModal
					title="Map parameters"
					importVersion={version}
					onCloseModal={closeModal}
				/>
			</If>
			<If condition={isOpenModal && isMapArchiving}>
				<RenderArchiveModal
					isOpenModal={isMapArchiving}
					isMap={true}
					closeModal={() => {
						runInAction(() => {
							setIsMapArchiving(false);
						});
					}}
					archive={archiveImport}
				/>
			</If>

		</div>
	);
}

export default EditMap;
