import {
	AreaEntity,
	BayEntity, 
} from '../../../Models/Entities';
import {
	convertToFixed, isANumber, submitFormOnEnter,
} from './PropertiesSidePanel';
import React, { useEffect, useState } from 'react';
import {
	baystateOptions,
	bayTypeOptions,
	callingTypeOptions, crossDirOptions,
	spotDirOptions,
} from '../../../Models/Enums';
import {
	action, autorun, observable, runInAction,
} from 'mobx';
import MapController from '../Map/MapController';
import alertToast from 'Util/ToastifyUtils';
import {
	EASTING_DEFAULT_VALUE, EASTING_INSIDE_BOUNDS_ERROR, EASTING_LESS_THAN_ZERO_ERROR,
	HEADING_ERROR, NORTHING_DEFAULT_ERROR, NORTHING_INSIDE_BOUNDS_ERROR, NORTHING_LESS_THAN_ZERO_ERROR,
} from 'Constants';
import { useLocalStore } from 'mobx-react';
import CollapsibleProperty from '../CollapsibleProperty';
import ErrorsAndWarnings from './ErrorsAndWarnings';
import { realWorldCoordinates, RealWorldCoordinates } from '../Map/Helpers/Coordinates';
import { getJsonObject, realWordCoordsToGeoJSONPoint } from '../Map/Helpers/GeoJSON';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { TextField } from 'Views/Components/TextBox/TextBox';
import InputWrapper, { InputType } from 'Views/Components/Inputs/InputWrapper';
import Bay from '../Map/MapObjects/Bay/Bay';
import { offsetAlongHeadingRealWorld, setCustomTag } from '../Map/Helpers/MapUtils';
import BayValidator, {
	BAY_DISTANCE_ERROR,
	BAY_DISTANCE_ERROR_2,
	BAY_IMPORTED,
	BAY_IMPORTED_INVALID_SHIFT,
	DUMP_LOC_BAY_TYPE_OPTIONS,
	PARKING_LOC_BAY_TYPE_OPTIONS,
	STOCKPILE_LOC_BAY_TYPE_OPTIONS
} from '../Map/MapValidators/BayValidator';
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import { isNumber } from 'lodash';
import InputField from "./PropertiesPanelComponents/InputField";
import UpdateBayCommand from "../ChangeTracker/ChangeTypes/UpdateBayCommand";
import CreateBayCommand from "../ChangeTracker/ChangeTypes/CreateBayCommand";

const BAY_SEQ_ERROR = 'Seq must be >= 1 and <= 65535';

/**
 * Render properties side panel for a bay entity
 */
// eslint-disable-next-line max-len
export default function BayProperties(props: { entity: BayEntity, map: MapController, isSnapped?: boolean, isBayTypeReadonly?: boolean, bayOptions?: unknown, multiBayButtonOnClick?: () => void }) {
	const {
		entity, map, isSnapped, isBayTypeReadonly, bayOptions, multiBayButtonOnClick
	} = props;
	// have a state to track if the 'shift bay' option is open or closed
	const [isShiftBayOpen, setIsShiftBayOpen] = useState(false);
	const [isMultiBayOpen, setIsMultiBayOpen] = useState(false);
	const [isMultiBayDisabled, setIsMultiBayDisabled] = useState(false);

	const nonProxyBayEntity = entity;

	// entity.id is not undefined even if bay is not yet confirmed, so checking if the entity exists in the lookup
	const _entity = map.getMapLookup().getEntity(entity.id, BayEntity);
	let bay = observable(entity);

	let isReadOnly;

	const location = useLocalStore(() => ({
		easting: undefined,
		northing: undefined,
	}));

	let bayType = Object.entries(bayTypeOptions)
		.filter(([value, _]) => !['INVALID', 'DUMPCRUSHER'].includes(value))  // HITMAT-1397 (Temporarily deactivated DUMPCRUSHER option)
		.map(([value, display]) => ({ display, value }));
	const spottingDirection = Object.entries(spotDirOptions)
		.filter(([value, _]) => value !== 'INVALID')
		.map(([value, display]) => ({ display, value }));

	// Advanced bay validation on selector mode or rerendering
	if (!!bayOptions) {
		bayType = Object.entries(bayOptions as any as object)
			.map(([value, display]) => ({ display, value }));
	}

	if (!!bay.areaId && bay.state !== BAY_IMPORTED) {
		// TODO: REFACTOR
		const area = map.getMapLookup().getEntity(bay.areaId, AreaEntity);
		const locType = area?.locType;
		if (!!locType) {
			switch (locType) {
				case 'PARKING':
					bayType = Object.entries(PARKING_LOC_BAY_TYPE_OPTIONS)
					.map(([value, display]) => ({ display, value }));
					break;
				case 'CRUSHER':
				case 'DIG':
					isReadOnly = isBayTypeReadonly ?? true;
					runInAction(() => {
						bay.bayType = (locType === 'CRUSHER') ? 'DUMPCRUSHER' : 'LOADING';
					});
					break;
				case 'DUMP':
					if (!bayOptions) {
						bayType = Object.entries(DUMP_LOC_BAY_TYPE_OPTIONS)
							.map(([value, display]) => ({ display, value }));
					}
					const bays = map.getMapLookup().getBaysByAreaId(area.id);
					if (!_entity && isBayTypeReadonly !== undefined) {
						isReadOnly = isBayTypeReadonly;
					} else {
						if (bays === undefined || bays.length <= 1) {
							isReadOnly = false;
						} else {
							isReadOnly = true;
						}
					}
					break;
				case 'STOCKPILE':
					bayType = Object.entries(STOCKPILE_LOC_BAY_TYPE_OPTIONS)
					.map(([value, display]) => ({ display, value }));
					break;
				default:
					console.error(`locType not found`);
					break;
			}
		}
	}

	// The spotting side is called cross direction in the current tool
	const spottingSide = Object.entries(crossDirOptions).map(([value, display]) => ({ display, value }));

	const callingType = Object.entries(callingTypeOptions)
		.filter(([value, _]) => value !== 'INVALID')
		.map(([value, display]) => ({ display, value }));
	const bayStatus = Object.entries(baystateOptions)
		.filter(([value, _]) => ['OPEN', 'CLOSED', 'FULL'].includes(value))
		.map(([value, display]) => ({ display, value }));
	
	const isLocationReadyOnly = bay.isImported || map.getEventHandler().getEventMode() === 'bay';// [HITMAT-1665] set isLocationReadyOnly to true if the bay is imported or the stateHandler is BayToolHandler. False if stateHandler is BayEditHandler

	const [isReadOnlyDirection, setIsReadOnlyDirection] = useState(false);
	const [isReadOnlyCallInType, setIsReadOnlyCallInType] = useState(true);
	
	const updateBay = (attributeToUpdate: string = '') => {
		map?.getEventHandler().emit('requestUpdate');
		map?.getEventHandler().emit('onPropertiesPanel', 'bay', bay);
	}

	const updateBayLocation = action(() => {
		const { easting, northing } = location;
		if (!easting || !northing || !map) {
			return;
		}
		const err = BayValidator.validateInsideOriginalArea({ northing, easting }, bay, map);
		if (!!err) {
			alertToast(err, 'error');

			// Set to the old value
			setLocationFromBay();

			return;
		}
		map.getMapLookup().updateEntity(bay);
		const coords: RealWorldCoordinates = { easting: Number(location.easting), northing: Number(location.northing) };
		bay.bayLocation = JSON.stringify(realWordCoordsToGeoJSONPoint(coords));
		// TODO: find out why it will not save witout this line
		map?.getEventHandler().emit('onPropertiesPanel', 'bay', bay);
		const mapParams = map.getImportVersion().maptoolparam;
		if (!!mapParams) {
			BayValidator.checkBayErrors(bay, mapParams, map);
		}
		updateBay('location');
	});

	const derivedProperties = { 
		elevation: isNumber(bay.elevation) ? bay.elevation.toFixed(2) : bay.elevation
	}

	const setLocationFromBay = () => {
		const { coordinates } = bay.bayLocation ? getJsonObject(bay.bayLocation) : { coordinates: [null, null] };
		runInAction(() => {
			[location.easting, location.northing] = coordinates;
		});
	};

	useEffect(() => {
		const updatePropertyFieldsDisposer = autorun(setLocationFromBay);

		return () => {
			return updatePropertyFieldsDisposer();
		};
	}, [bay]);

	useEffect(() => {
		map.getEventHandler().addListener('onSetMultiBayDisabledState', onSetMultiBayDisabledState);
		return () => {
			map.getEventHandler().removeListener('onSetMultiBayDisabledState', onSetMultiBayDisabledState);
		};
	}, []);

	const onSetMultiBayDisabledState = (isReadOnly: boolean) => {
		console.log(`onSetMultiBayDisabledState: isReadOnly ${isReadOnly}`);
		setIsMultiBayDisabled(isReadOnly);
	};

	const validateBaySequence = (value: number): string | undefined => {
		if (Number.isNaN(value) || value < 1 || value > 65535 || Number.isNaN(value)) {
			return BAY_SEQ_ERROR;
		}
		return undefined;
	};

	const onValidateHeading = (value: number, startOrEndNode: string): string | undefined => {
		if ((!!value && value < 0) || (value === undefined) || !startOrEndNode) {
			return HEADING_ERROR;
		}
		return undefined;
	};

	const onValidateNorthing = (value: number): string | undefined => {
		let realWorldCoords;
		if (!!location.easting && !!value) {
			realWorldCoords = { easting: location.easting, northing: value };
		}

		if (realWorldCoords) {
			const insideBounds = map?.getEventHandler().getRenderer().isPointInMapBounds(realWorldCoords);
			if (value < 0) {
				return NORTHING_LESS_THAN_ZERO_ERROR;
			}
			if (!insideBounds) {
				return NORTHING_INSIDE_BOUNDS_ERROR;
			}
			const err = BayValidator.validateInsideOriginalArea(realWorldCoords, bay, map);
			if (!!err) {
				return err;
			}
		} else {
			return NORTHING_DEFAULT_ERROR;
		}
		return undefined;
	};

	// TODO: this should be moved to validator
	const onValidateEasting = (value: number): string | undefined => {
		let realWorldCoords;
		if (!!location.northing && !!value) {
			realWorldCoords = { easting: value, northing: location.northing };
		}

		if (realWorldCoords) {
			const insideBounds = map?.getEventHandler().getRenderer().isPointInMapBounds(realWorldCoords);
			if (value < 0) {
				return EASTING_LESS_THAN_ZERO_ERROR;
			}
			if (!insideBounds) {
				return EASTING_INSIDE_BOUNDS_ERROR;
			}
			const err = BayValidator.validateInsideOriginalArea(realWorldCoords, bay, map);
			if (!!err) {
				return err;
			} else {
				updateBayLocation();
				map.getTracker().addChange(new UpdateBayCommand(bay))
			}
		} else {
			return EASTING_DEFAULT_VALUE;
		}
		return undefined;
	};

	// validations for Bay properties section on selector mode
	const updateBayProperties = () => {

		if (!bay) {
			return;
		}

		const oldBay = new BayEntity(bay);

		if (!bay.bayType) {
			console.log(`updateBayProperties: baytype not found`);
			return;
		}

		if ((bay.bayType === 'DUMPCRUSHER' || bay.bayType === 'DUMPOVEREDGE' || bay.bayType === 'LOADING')) {
			bay.spotDir = 'BACKIN';
			setIsReadOnlyDirection(true);
		}
		if (bay.bayType === 'DUMPPADDOCK') {
			setIsReadOnlyDirection(false);
		}
		if (bay.bayType === 'FUELLING' || bay.bayType === 'PARKING') {
			bay.callingType = 'SINGLEAHT';
			setIsReadOnlyCallInType(true);
			setIsReadOnlyDirection(false);
		}

		// validation for call in type
		if (bay.bayType === 'DUMPPADDOCK' || bay.bayType === 'DUMPOVEREDGE') {
			bay.callingType = 'SINGLEAHT';
			setIsReadOnlyCallInType(true);
		}
		if(bay.bayType === 'DUMPCRUSHER') {
			bay.callingType = 'INFINITE';
			setIsReadOnlyCallInType(true);
		}
		if (bay.bayType === 'LOADING') {
			bay.callingType = 'INFINITE';
			setIsReadOnlyCallInType(false);
		}

		map.getEventHandler().emit('requestUpdate');
	};

	const shiftBayOption = (
		<Button
			className="plus-btn no-background"
			icon={{ icon: 'more-horizontal', iconPos: 'icon-right' }}
			colors={Colors.White}
			display={Display.Text}
			onClick={() => setIsShiftBayOpen(prevState => !prevState)}
		/>
	);

	const multiBayOption = (
		<Button
			className="plus-btn no-background multi-bay"
			icon={{ icon: 'more-horizontal', iconPos: 'icon-right' }}
			colors={Colors.White}
			display={Display.Text}
			onClick={ multiBayButtonOnClick ?? (() => setIsMultiBayOpen(prevState => !prevState)) }
		/>
	);

	return (
		<>
			<h6>Bay Identification</h6>
			<InputField model={bay} label="ID" modelProperty="bayId" isReadOnly />
			<InputField
				key={`${bay._clientId}_seq`}
				model={bay}
				label="Seq"
				modelProperty="baySeq"
				isReadOnly // The sequence number should always be readonly, only the AHS server should define this value
				// onValidateInput={validateBaySequence}
				maxLength={5}
				errorsObject={bay.propertyValidationErrors}
				isNumber
			/>
			<InputField model={bay} label="Version" modelProperty="bayVersion" isReadOnly />

			<div className="section-divider" />

			{isShiftBayOpen
				&& <ShiftBay bay={bay} map={map} hidePanel={() => setIsShiftBayOpen(false)} />}

			<CollapsibleProperty propertyTitle="Bay Location" displayProperty moreButton={shiftBayOption}>
				<InputField
					model={location}
					label="Easting"
					modelProperty="easting"
					propertyUnit="m"
					isReadOnly={isLocationReadyOnly}
					isNumber
					onUpdate={updateBayLocation}
					renderDisplayValue={value =>
						!isANumber(value) ? '' : convertToFixed(value, 2)}
					onValidateInput={(value: any) => onValidateEasting(value)}
					errorsObject={bay.propertyValidationErrors}
				/>
				<InputField
					model={location}
					label="Northing"
					modelProperty="northing"
					propertyUnit="m"
					isReadOnly={isLocationReadyOnly}
					isNumber
					onUpdate={updateBayLocation}
					renderDisplayValue={value =>
						!isANumber(value) ? '' : convertToFixed(value, 2)}
					onValidateInput={(value: any) => onValidateNorthing(value)}
					errorsObject={bay.propertyValidationErrors}
				/>
				<InputField
					model={derivedProperties}
					label="Elevation"
					modelProperty="elevation"
					propertyUnit="m"
					isReadOnly={true}
					isNumber
					errorsObject={bay.propertyValidationErrors}
				/>
				<InputField
					model={bay}
					label="Heading"
					modelProperty="heading"
					propertyUnit="°"
					renderDisplayValue={value => convertToFixed(value, 1)}
					isNumber
					isReadOnly={isLocationReadyOnly}
					onUpdate={() => updateBay('heading')}
					onValidateInput={(value: any) => onValidateHeading(value, 'endNode')}
					alterValueBeforeConfirm={value => value % 360}
					errorsObject={bay.propertyValidationErrors}
				/>
				<InputField model={bay} label="Area" modelProperty="areaName" isReadOnly />
			</CollapsibleProperty>

			<div className="section-divider" />

			{isMultiBayOpen
				&& <MultiBay bay={nonProxyBayEntity} map={map} hidePanel={() => setIsMultiBayOpen(false)} isAddDisabled={isMultiBayDisabled} />}

			<CollapsibleProperty propertyTitle="Bay Properties" displayProperty moreButton={multiBayOption}>
				<RenderInformationCombobox
					model={bay}
					label="Type"
					modelProperty="bayType"
					options={bayType}
					readonly={bay.isImported || isReadOnly}
					onAfterChange={updateBayProperties}
				/>

				<div className="info-section">
					<div className="properties-input"><p>Spotting</p></div>
				</div>
				<RenderInformationCombobox
					model={bay}
					label="Direction"
					modelProperty="spotDir"
					options={spottingDirection}
					// eslint-disable-next-line max-len, no-nested-ternary
					readonly={bay.isImported || isSnapped ? true : bay.bayType === 'LOADING' || bay.bayType === 'DUMPOVEREDGE' || bay.bayType === 'DUMPCRUSHER' ? true : isReadOnlyDirection}
					onAfterChange={() => updateBay('spotDir')}
				/>

				<RenderInformationCombobox
					model={bay}
					label="Side"
					modelProperty="crossDir"
					options={spottingSide}
					readonly={bay.isImported}
					onAfterChange={() => updateBay('crossDir')}
				/>

				<RenderInformationCombobox
					model={bay}
					label="Call in Type"
					modelProperty="callingType"
					options={callingType}
					readonly={bay.isImported || bay.bayType !== 'LOADING' && isReadOnlyCallInType}
					onAfterChange={() => updateBay('callingType')}
				/>

				<RenderInformationCombobox
					model={bay}
					label="Status"
					modelProperty="bayState"
					options={bayStatus}
					readonly={bay.isImported}
					onAfterChange={() => updateBay('bayState')}
				/>
			</CollapsibleProperty>
			<div className="section-divider" />
			<ErrorsAndWarnings key={bay.getModelId()} mapObject={bay} mapController={map} />
			<div className="section-divider" />
		</>
	);
}

/**
* Shift bay functionality and UI component
*/
function ShiftBay({ bay, map, hidePanel }: { bay: BayEntity, map: MapController, hidePanel: () => void }) {
	const bayProps = observable({
		front: 0,
		rear: 0,
		left: 0,
		right: 0,
	});

	const shiftBay = async (bay: BayEntity, distances: any) => {
		const bayLocation = getJsonObject(bay.bayLocation);
		const bayHeading = bay.heading;

		// The directions to shift the bay by [forward, reverse, left, right]
		const directionNames = Object.keys(distances);
		const directionAngles = [0, 180, 270, 90];

		directionNames.forEach((directionName: string, i: number) => {
			const { northing, easting } = offsetAlongHeadingRealWorld(
				distances[directionName],
				bayHeading + directionAngles[i],
			);

			runInAction(() => {
				// @ts-ignore
				bayLocation.coordinates[1] += northing;
				// @ts-ignore
				bayLocation.coordinates[0] += easting;
			});
		});

		// @ts-ignore
		const easting = bayLocation.coordinates[0];
		// @ts-ignore
		const northing = bayLocation.coordinates[1];

		// check if the bay location is valid.
		const bayArea = BayValidator.getBayArea({ northing, easting }, map);
		const err = BayValidator._validateInsideOriginalArea(bayArea, bay);
		if (!!err) {
			alertToast(err, 'error');
			assignDefaults();
			return;
		}

		// shift the bay here so the rest of the bays in the area can be validated.
		// Set the final location
		// Do not need to update the map lookup, since we are just updating a map property
		runInAction(() => {
			bay.bayLocation = bayLocation;
		});

		// check bay validation for the dot error or warning representation
		const mapParams = map.getImportVersion().maptoolparam;
		if (!!mapParams) {
			await BayValidator.checkBayErrors(bay, mapParams, map, false, true);
		}

		// run the bay validation again so we can get rid of the bay errors in the area
		if (mapParams && bay.areaId) {
			// bayValidation(bay, map, true);
			const getBayList = map.getMapLookup().getBaysByAreaId(bay.areaId);
			getBayList?.map(async _bayEntity => {
				if (_bayEntity.state !== 'IMPORTED') {
					await BayValidator.checkBayErrors(_bayEntity, mapParams, map);
				}
			});
			if (!!bay.areaId && bay.areaId !== bay.areaId.toString()) {
				const _getBayList = map.getMapLookup().getBaysByAreaId(bay.areaId);
				_getBayList?.map(async _bayEntity => {
					if (_bayEntity.state !== 'IMPORTED') {
						await BayValidator.checkBayErrors(_bayEntity, mapParams, map);
					}
				});
			}
		}
	};

	const assignDefaults = () => {
		runInAction(() => {
			bayProps.front = bayProps.rear = bayProps.left = bayProps.right = 0;
		});
	};

	const handleShiftBay = () => {
		setCustomTag('properties-panel', 'shift-bay-tool');
		if (bay.isImported) {
			alertToast(BAY_IMPORTED_INVALID_SHIFT, 'error');
			return;
		}
		// reset values to zero, as the fields can be undefined
		if (bayProps.left === undefined) {
			runInAction(() => {
				bayProps.left = 0;
			});
		}
		if (bayProps.right === undefined) {
			runInAction(() => {
				bayProps.right = 0;
			});
		}
		if (bayProps.front === undefined) {
			runInAction(() => {
				bayProps.front = 0;
			});
		}
		if (bayProps.rear === undefined) {
			runInAction(() => {
				bayProps.rear = 0;
			});
		}
		shiftBay(bay, bayProps);
		map.getEventHandler().emit('requestUpdate');
		assignDefaults();
	};

	return (
		<div className="shift-bay-popout">
			<div className="additional-properties-input">
				<div className="section-header">
					<h6>Shift Bay</h6>
					<Button
						className="close-button no-background"
						icon={{ icon: 'cross', iconPos: 'icon-bottom' }}
						colors={Colors.White}
						display={Display.Text}
						onClick={() => {
							hidePanel();
						}}
					/>
				</div>
				<div className="section-divider-shift-bay" />
				<div className="flex">
					<NumberTextField
						className="shift-bay-styling"
						model={bayProps}
						label="Front"
						modelProperty="front"
						inputProps={{
							maxLength: 10,
							onKeyDown: submitFormOnEnter(handleShiftBay)
						}}
					/>
					<span className="unit-shift-bay">m</span>
				</div>
				<div className="flex">
					<NumberTextField
						className="shift-bay-styling"
						model={bayProps}
						label="Rear"
						modelProperty="rear"
						inputProps={{
							maxLength: 10,
							onKeyDown: submitFormOnEnter(handleShiftBay)
						}}
					/>
					<span className="unit-shift-bay">m</span>
				</div>
				<div className="flex">
					<NumberTextField
						className="shift-bay-styling"
						model={bayProps}
						label="Left"
						modelProperty="left"
						inputProps={{
							maxLength: 10,
							onKeyDown: submitFormOnEnter(handleShiftBay)
						}}
					/>
					<span className="unit-shift-bay">m</span>
				</div>
				<div className="flex">
					<NumberTextField
						className="shift-bay-styling"
						model={bayProps}
						label="Right"
						modelProperty="right"
						inputProps={{
							maxLength: 10,
							onKeyDown: submitFormOnEnter(handleShiftBay)
						}}
					/>
					<span className="unit-shift-bay">m</span>
				</div>
				<Button type="submit" className="shift-bay-submit btn--primary" onClick={handleShiftBay}>
					Confirm
				</Button>
			</div>
		</div>
	);
}

/**
* Multi  bay functionality and UI component
*/
function MultiBay({ bay, map, hidePanel, isAddDisabled }: { bay: BayEntity, map: MapController, hidePanel: () => void, isAddDisabled: boolean }) {
	// fill row state
	let fillRow = false;
	console.log(`Creating MULTIBAY with isAddDisabled = ${isAddDisabled}`);
	// declaring the options for the direction dropdown
	const bayDirections: { [key in string]: string } = {
		FRONT: 'Front',
		REAR: 'Rear',
		LEFT: 'Left',
		RIGHT: 'Right',
	};

	const bayDirectionOptions = Object.entries(bayDirections)
		.map(([value, display]) => ({ display, value }));

	// prop for distance
	const bayProps = observable({
		distance: 9,
		count: 0,
		direction: 'FRONT',
	});

	const handleMultiBay = async () => {
		setCustomTag('properties-panel', 'multi-bay-tool');
		let { distance, count, direction } = bayProps;

		const tracker = map.getTracker();

		const importVersion = map.getImportVersion();
		const bayToBayMinDistance = importVersion.maptoolparam?.bayToBay ?? 9;

		// do the multi bay logic here.
		let isError = false;
		if (distance < bayToBayMinDistance) {
			isError = true;
			runInAction(() => {
				bayProps.distance = bayToBayMinDistance;
			});
		}
		if (count < 0) {
			isError = true;
			runInAction(() => {
				bayProps.count = 0;
			});
		}
		if (isError) {
			// both errors use this msg.
			alertToast(BAY_DISTANCE_ERROR_2(bayToBayMinDistance), 'error');
			return;
		}
		let index = 1;
		const createdBays = [];

		// bay.id is not undefined even if bay is not yet confirmed, so checking if the entity exists in the lookup
		const entity = map.getMapLookup().getEntity(bay.id, BayEntity);
		// if bay is not yet confirmed but the user hits add
		if (!entity) {
			map.getEventHandler().emit('toggleConfirmCreation', false);
			bay.id = bay._clientId;
			tracker.addChange(new CreateBayCommand(bay));

			// Update this.isBayConfirmed to true to prevent from deleting reference bayMapObject in BayEditHandler
			const isFromPropertiesPanel = true;
			map.getEventHandler().emit('onConfirmMapObjectCreation', isFromPropertiesPanel);
		}

		while (count > 0 || fillRow) {
			const bayDistance = distance * index;
			index++;
			// create the new bay here
			const newBay = new BayEntity({ ...bay });
			newBay.id = newBay._clientId;
			newBay.state = 'NEW_OBJECT';
			newBay.isImported = false;
			newBay.baySeq = 0;
			newBay.mapObjectErrorss = [];

			newBay.bayLocation = getJsonObject(newBay.bayLocation);

			// @ts-ignore
			newBay.bayLocation = realWordCoordsToGeoJSONPoint(realWorldCoordinates(
				// @ts-ignore
				newBay.bayLocation.coordinates[1], newBay.bayLocation.coordinates[0]));

			const { bayLocation } = newBay;

			let directionAngle = 0;
			if (direction === 'FRONT') {
				directionAngle = 0;
			}
			if (direction === 'REAR') {
				directionAngle = 180;
			}
			if (direction === 'LEFT') {
				directionAngle = 270;
			}
			if (direction === 'RIGHT') {
				directionAngle = 90;
			}

			// get the new bay location for the new bays
			let { northing, easting } = offsetAlongHeadingRealWorld(
				bayDistance,
				bay.heading + directionAngle,
			);

			// set the baylocation
			runInAction(() => {
				// @ts-ignore
				bayLocation.coordinates[1] += northing;
				// @ts-ignore
				bayLocation.coordinates[0] += easting;
			});
			// @ts-ignore
			easting = bayLocation.coordinates[0];
			// @ts-ignore
			northing = bayLocation.coordinates[1];

			// if the bay location is invalid, stop
			const bayArea = BayValidator.getBayArea({ northing, easting }, map);
			if (!bayArea) {
				if (index === 2) {
					alertToast('This operation is not allowed. A Bay must be located within an Autonomous Area', 'error');
				}
				break;
			}
			// TODO: this can probably be related with lookup.updateEntity
			BayValidator.updateBayArea(newBay, bayArea, map);
			// if checkbay distance is invalid, stop
			const mapParams = map.getImportVersion().maptoolparam;
			if (!!mapParams) {
				const result = BayValidator.checkBayDistance(newBay, mapParams, map);
				if (!result) {
					if (index === 2) {
						alertToast(BAY_DISTANCE_ERROR(mapParams.bayToBay), 'error');
					}
					//HITMAT-2027 Clear bay details from area so that ghost bay doesn't stay in layers panel
					BayValidator.updateBayArea(newBay, {id: '', areaName: ''} as AreaEntity, map);
					break;
				}
			}
			const bayMapObject = new Bay(newBay, map);

			// add the new bay map object to the lookup table
			map.getMapLookup().createEntity(newBay);
			map.getEventHandler().getStateHandler()?.getRenderer().addObject(bayMapObject, true);
			map.getMapRenderer().rerender();

			const bayMapObjectId = map.getMapLookup().getMapObjectId(newBay.id, 'bay');
			const bayObject = map.getMapRenderer().getObjectById(bayMapObjectId);

			// special validation where any part of the bay should not be outside the area.
			let flag = true;
			// step 1: get bay outline points
			const bayPoints = bayMapObject.getBayOutlinePoints();
			// step 2: convert the points to northing and easting
			const realWorldCoords = bayPoints.map(x => {
				const { northing, easting } = map.getMapRenderer().getRealWorldCoords(map.getMapRenderer().unproject(x));
				return [easting, northing];
			});
			// step 3: run the getBayArea function to see if it has an area around it.
			realWorldCoords.map(coord => {
				const easting = coord[0];
				const northing = coord[1];
				const bayArea = BayValidator.getBayArea({ northing, easting }, map);
				if (!bayArea) {
					if (flag) {
						flag = false;
					}
				}
			});
			// TODO: is this needed?? can proably be replaced with updateEntity
			BayValidator.updateBayArea(newBay, bayArea, map);
			// exit if a new bay is invalid
			if (!flag) {
				// deleteEntity should be here
				// deleteEntity cannot be in the if (flag) {} statement, even if the newBay id was removed when it was called,
				// when coming here, the newBay id still exist and I don't know why ...
				map.getMapLookup().deleteEntity(newBay);
				map.removeMapObject(newBay, bayObject);
				map.getMapRenderer().rerender();
				break;
			}
			tracker.addChange(new CreateBayCommand(newBay));

			createdBays.push(newBay);
			count--;
		}


		map.getEventHandler().emit('requestUpdate');
	};

	return (
		<div className="shift-bay-popout">
			<div className="additional-properties-input">
				<div className="section-header">
					<h6>Multi-Bay Creation</h6>
					<Button
						className="close-button no-background"
						icon={{ icon: 'cross', iconPos: 'icon-bottom' }}
						colors={Colors.White}
						display={Display.Text}
						onClick={() => {
							hidePanel();
						}}
					/>
				</div>
				<div className="section-divider-shift-bay" />
				<div className="flex">
					<RenderInformationCombobox
						model={bayProps}
						label="Type"
						modelProperty="direction"
						options={bayDirectionOptions}
						customStyle="properties-input custom"
					/>
				</div>
				<div className="flex">
					<TextField
						className="shift-bay-styling"
						id="bayDistance"
						model={bayProps}
						label="Distance"
						modelProperty="distance"
						inputProps={{
							maxLength: 10,
							onKeyDown: submitFormOnEnter(handleMultiBay)
						}}
					/>
					<span className="unit-shift-bay multi-bay">m</span>
				</div>
				<div className="flex">
					<TextField
						className="shift-bay-styling count"
						model={bayProps}
						label="Count"
						modelProperty="count"
						inputProps={{
							maxLength: 10,
							onKeyDown: submitFormOnEnter(handleMultiBay)
						}}
					/>
				</div>
				<InputWrapper inputType={InputType.CHECKBOX} className="input-wrapper-styling">
					<input
						id="fillrow-checkbox"
						type="checkbox"
						onChange={() => { fillRow = !fillRow; }}
					/>
					<label htmlFor="fillrow-checkbox">Fill Row</label>
				</InputWrapper>
				<Button type="submit" className="shift-bay-submit btn--primary" onClick={handleMultiBay} disabled={(bay.areaId === undefined) || isAddDisabled}>
					Add
				</Button>
			</div>
		</div>
	);
}
