import { NodeEntity } from '../../../../Models/Entities';
import { MapController } from '../../index';
import { calcDistanceBetweenNodes } from '../Helpers/MapUtils';
import alertToast from '../../../../Util/ToastifyUtils';
import MapValidator from './MapValidator';
import MapStore from "../MapStore";

export const CONNECTIVITY_INVALID_DISTANCE = 'Connections must be between 1m and 10m apart.';
export const CONNECTIVITY_INVALID_DIRECTION = 'Creating this connectivity is not allowed.';

export default class ConnectivityValidator extends MapValidator {
	/**
	 * Check if distance between nodes to establish connecitivty is valid.
	 * If invalid, show toast message
	 *
	 * @param startEntity
	 * @param endEntity
	 * @param controller
	 * @param showErrorToast
	 * @returns true if distance is valid
	 */
	public static validateConnectivityDistance(
		startEntity: NodeEntity,
		endEntity: NodeEntity,
		controller: MapController,
		showErrorToast?: boolean,
	): boolean {
		const params = controller.getImportVersion().maptoolparam;
		const nodeDistMin = params?.nodeDistMin ?? 1;
		const nodeDistMax = params?.nodeDistMax ?? 10;
		const distance = calcDistanceBetweenNodes(startEntity, endEntity);
		const isValid = distance > nodeDistMin && distance < nodeDistMax;
		console.log(`distance: ${distance} nodeDistMin ${nodeDistMin} nodeDistMax: ${nodeDistMax}`);
		if (!isValid && !!showErrorToast) {
			alertToast(CONNECTIVITY_INVALID_DISTANCE, 'error');
		}
		return isValid;
	}

	public static validateConnectivityDirections(endNode: NodeEntity, startNode: NodeEntity, mapStore: MapStore): boolean {
		// Disallow creating connectivities with start nodes with special tasks
		if (startNode.task !== 'HAULING') {
			return false;
		}

		// Get the speeds of the nodes, if the end node is a special task, get the speed of the node before it to
		// determine the direction of the path
		let endNodeSpeed = endNode.speed;
		const startNodeSpeed = startNode.speed;
		if (endNodeSpeed === 0) {
			// If it is the end node, we can be pretty confident it should have a previous node
			const previousNode = mapStore.getEntity(endNode.previousNodeId!, NodeEntity);
			endNodeSpeed = previousNode.speed;
		}

		const isEndNodeReversePoint = endNode.task === 'REVERSEPOINT';
		const isEndNodeParking = endNode.task === 'PARKING';

		const isEndNodeForward = endNodeSpeed > 0;
		const isStartNodeForward = startNodeSpeed > 0;
		const areBothNodesForward = isEndNodeForward && isStartNodeForward;
		const areBothNodesReverse = !isEndNodeForward && !isStartNodeForward;

		console.log(isEndNodeReversePoint, isEndNodeParking, isEndNodeForward, isStartNodeForward, areBothNodesForward, areBothNodesReverse);

		// If the end node is a hauling task, the directions need to be the same
		if (!isEndNodeParking && !isEndNodeReversePoint) {
			return areBothNodesForward || areBothNodesReverse;
		}

		// If the end node is a reverse point, the directions need to be different
		if (isEndNodeReversePoint) {
			return !areBothNodesReverse && !areBothNodesForward
		}

		// If the end node is parking, the direction can only be:
		// - Forward -> forward
		// - Reverse -> forward
		if (isEndNodeParking) {
			return (isEndNodeForward && isStartNodeForward) || (!isEndNodeForward && isStartNodeForward);
		}

		// Return false by default
		return false;
	}

}