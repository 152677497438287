import {Coordinates, RealWorldCoordinates} from "../../../Helpers/Coordinates";
import {LinkEntity, NodeEntity} from "../../../../../../Models/Entities";
import PathGenerationHelper from "./PathGenerationHelper";
import PathRenderHelper from "./PathRenderHelper";
import MapStateHandler from "../../MapStateHandler";
import PathPropertiesPanelHelper from "./PathPropertiesPanelHelper";
import {Waypoint, WaypointList} from "./Waypoint";
import {
	calcDistanceBetweenCoords,
	calcHeading,
	getOffsetAlongHeading,
} from "../../../Helpers/MapUtils";
import {MapEventHandler, MapRenderer, NodeGraphic} from "../../../../index";
import PathValidationHelper from "./PathValidationHelper";
import {linkReferencePath} from "../../../MapStateHandlerHelpers/PathToolHelper";
import MapStore from "../../../MapStore";
import PathSnapHelper, {SnapOption} from "./PathSnapHelper";
import ChangeTracker from "../../../../ChangeTracker/ChangeTracker";
import CreatePathCommand from "../../../../ChangeTracker/ChangeTypes/CreatePathCommand";


export default class PathManager {
	public readonly waypoints: WaypointList;
	protected link: LinkEntity | undefined;

	public readonly propertiesHelper: PathPropertiesPanelHelper;
	public readonly generationHelper: PathGenerationHelper;
	public readonly renderHelper: PathRenderHelper;
	public readonly validationHelper: PathValidationHelper;
	public readonly snapHelper: PathSnapHelper;

	public readonly mapRenderer: MapRenderer;
	public readonly mapStore: MapStore;
	public readonly mapEventHandler: MapEventHandler;
	public readonly mapTracker:  ChangeTracker;

	protected isEditMode = false;
	protected isConfirmed = false;

	protected hasInitialised = false;

	// Ignore the drag actions
	// Situations when we currently ignore a drag action:
	// 	1. When an initial placement of a waypoint causes an error returned from the path generation (we want to ignore future updates)
	// 	2. When a waypoint has been placed with a shift/ctrl click (when the heading is already specified)
	protected ignoreDragAction = false;

	// Represents when a user is in the middle of performing an action
	// This is used to prevent certain keypresses from occuring while the user is mid-action
	// It is also used to prevent rending of guidelines
	protected userPerformingAction = false;

	protected otherLinks: LinkEntity[] = [];

	constructor(mapStateHandler: MapStateHandler) {
		this.mapRenderer = mapStateHandler.getRenderer();
		this.mapStore = mapStateHandler.getLookup();
		this.mapEventHandler = mapStateHandler.getEventHandler();
		this.mapTracker = mapStateHandler.getController().getTracker();
		this.waypoints = new WaypointList();


		this.propertiesHelper = new PathPropertiesPanelHelper(this, mapStateHandler.getController(), this.waypoints);
		this.renderHelper = new PathRenderHelper(mapStateHandler.getController(), this.waypoints);
		this.validationHelper = new PathValidationHelper(this.waypoints, this);
		this.generationHelper = new PathGenerationHelper(
			mapStateHandler.getController(), this.propertiesHelper, this.validationHelper);
		this.snapHelper = new PathSnapHelper(mapStateHandler.getController(), this);
	}

	public startAction() {
		this.userPerformingAction = true;
		this.renderHelper.setBuildMode(false);
	}

	public stopAction() {
		this.userPerformingAction = false;
		this.renderHelper.setBuildMode(!this.isEditMode);

		if (this.isIgnoringDragAction()) {
			this.stopIgnoringDragAction();
		}

		// Restore the last successful waypoints if needed
		this.generationHelper.restoreLastSuccessfulWaypoints(this.waypoints);

		this.processPathUpdateWhenReady();
	}

	public isUserPerformingAction() {
		return this.userPerformingAction;
	}

	public init() {
		if (this.hasInitialised) return;

		this.hasInitialised = true;
		this.propertiesHelper.displayPanel();
		this.isEditing() ? this.setEditing() : this.setCreating();

		this.renderHelper.setDefaultCursor();

		this.mapEventHandler.updateSingleClickDelay(200);
	}

	/**
	 * Clear everything and reset the state, if we want to continue using the tool
	 */
	public reset() {
		this.generationHelper.triggerAbort();
		this.renderHelper.dispose();
		this.propertiesHelper.resetProperties();
		this.propertiesHelper.hideConfirmButton();
	}

	public dispose() {

		if (!this.isConfirmed) {
			// Make sure to reset this if the path was already confirmed
			this.renderHelper.clearDisplay(true);
		}

		this.propertiesHelper.dispose();

		this.generationHelper.triggerAbort();

		this.renderHelper.dispose();

		// Reset the single click delay
		this.mapEventHandler.updateSingleClickDelay(0);
	}

	public isProcessing() {
		return this.generationHelper.isRequestInProgress() || this.renderHelper.isCurrentlyRendering();
	}

	public addWaypoint(coords: RealWorldCoordinates, waypoint: Partial<Waypoint>, snapOption?: SnapOption) {
		// Always calculate a straight path waypoint initially (if there is a previous waypoint)
		let heading = waypoint?.heading ?? 0;

		// If the first waypoint was short clicked, we need to update its heading
		const firstWaypoint = this.waypoints.firstWaypoint;
		if (!!firstWaypoint && firstWaypoint.heading === 0 && firstWaypoint.shortClicked && heading === 0) {
			heading = calcHeading(firstWaypoint?.coordinates!, coords);

			// Account for the direction of the path
			if (firstWaypoint.direction === 'reverse') {
				heading = (heading + 180) % 360;
			}

			// Update the heading of the first waypoint if needed
			if (!this.waypoints.isFullPath() && firstWaypoint?.shortClicked && waypoint.shortClicked === true) {
				firstWaypoint?.setHeading(heading);
			}
		} else if (this.waypoints.hasWaypoint() && waypoint.heading === undefined) {
			const lastWaypoint = this.waypoints.mostRecentlyPlacedWaypoint;
			heading = lastWaypoint?.heading ?? 0;
		}

		const task = this.propertiesHelper.getExpectedTaskForWaypoint();
		const direction = this.propertiesHelper.getExpectedDirectionForWaypoint(task);
		const connections = !!snapOption ? [snapOption.entityId] : [];
		this.waypoints.addWaypoint(coords, {
			heading,
			task,
			direction,
			connections,
			...waypoint,
		});

		this.processPathUpdate();
	}

	public addStraightWaypoint(mouseCoords: RealWorldCoordinates, waypoint: Partial<Waypoint>) {
		if (!this.waypoints.hasWaypoint()) {
			this.addWaypoint(mouseCoords, waypoint);
			return;
		}

		// Calculate the distance between the mouseCoords and the last placed waypoint
		const lastWaypoint = this.waypoints.mostRecentlyPlacedWaypoint!;

		// Check which direction we want to add the straight waypoint
		const heading = lastWaypoint.direction === 'reverse'
			? (lastWaypoint.heading + 180) % 360 : lastWaypoint.heading;

		const newCoords = this.getStraightWaypointLocation(lastWaypoint.coordinates!, mouseCoords, heading);
		this.addWaypoint(newCoords, {
			...waypoint,
			heading: lastWaypoint.heading,
		});
	}

	/**
	 * Get the new coordinates for a straight waypoint
	 *
	 * @param lastWaypoint location of the previous waypoint
	 * @param mouseCoords current coordinates of the mouse
	 * @param lastWaypointHeading heading of the previoous waypoint
	 */
	public getStraightWaypointLocation(lastWaypoint: RealWorldCoordinates, mouseCoords: RealWorldCoordinates, lastWaypointHeading: number) {
		const { northing: northing1, easting: easting1 } = lastWaypoint;
		const { northing: northing2, easting: easting2 } = mouseCoords;

		const distance = calcDistanceBetweenCoords(easting1, northing1, easting2, northing2);
		return getOffsetAlongHeading(lastWaypoint, distance, lastWaypointHeading);
	}

	public removeWaypoints() {
		this.waypoints.removeAllWaypoints();
		this.link = undefined;

		this.renderHelper.clearLastReturnedLink();

		this.processPathUpdate();
	}

	public processPathUpdateWhenReady() {
		// If we are currently waiting for a request, we will try again in 50 milliseconds
		if (this.isProcessing()) {
			setTimeout(this.processPathUpdateWhenReady.bind(this), 50);
		} else {
			this.processPathUpdate();
		}
	}

	public processPathUpdate() {
		return new Promise<void>(async (resolve, _reject) => {
			if (this.waypoints.isFullPath()) {
				await this.requestNewLink();
			} else {
				this.propertiesHelper.getErrorsAndWarnings().clear();
				this.renderHelper.updateDisplay();
			}

			resolve();
		});
	}

	public async requestNewLink() {
		const response = await this.generationHelper.performRequest(this.waypoints);
		if (response == undefined) {
			// Handle the case when this waypoint has caused an issue
			if (response === undefined) {
				// If the way point that had an error can be deleted
				if (this.waypoints.removeUnconfirmedWaypoint()) {
					// There is no need to disable drag operations if the user is not currently performing an operation.
					if (this.userPerformingAction) {
						this.startIgnoringDragAction();
					}
					await this.processPathUpdate();
				}
				// If the user operation is finished, restore the way point before the error.
				if (!this.userPerformingAction) {
					this.generationHelper.restoreLastSuccessfulWaypoints(this.waypoints);
				}
			}

			return;
		}

		// end point was snapped
		if (!this.isEditMode && this.waypoints.isEndSnapped()) {
			// Change to edit mode
			this.setEditing();
			this.mapEventHandler.setMapEventState('edit_path', this);
		}

		// Confirm a waypoint that has at least one successful request
		this.waypoints.confirmWaypoint();

		this.link = response.link;
		this.otherLinks = response.otherLinks;

		this.renderHelper.updateDisplay(this.link, this.otherLinks);
	}

	public setCreating() {
		this.isEditMode = false;
		this.renderHelper.setBuildMode(!this.isEditMode);
		this.propertiesHelper.handleChangeToToolMode();
	}

	public setEditing() {
		this.isEditMode = true;
		this.renderHelper.setDefaultCursor();
		this.renderHelper.setBuildMode(!this.isEditMode);

		this.propertiesHelper.handleChangeToToolMode();
	}

	/**
	 * Try to toggle a mid waypoint.
	 * Returns true if an attempt was made to toggle a mid waypoint. eg. If there are no available mid waypoint spaces
	 * left the method will still return true. The method will return false if no attempt was made to toggle a mid
	 * waypoint
	 *
	 * @param location
	 * @param callback
	 */
	public tryToggleMidWaypoint(location: Coordinates, callback?: (success: boolean) => void): boolean {
		const mapObject = this.mapEventHandler.getController()
			.getMapObjectAtCoordinates(location, ['node']);
		if (!mapObject || !(mapObject instanceof NodeGraphic)) {
			console.log('Map object is not a node', mapObject);
			return false; // We should confirm the path if we hit this
		}

		const node = mapObject.getEntity();

		// These should reference the same object if it is a node in this link
		const isNodeInLink = node.sublink?.link?.getModelId() == this.link?.getModelId();
		if (!isNodeInLink) {
			console.log('Map object is not a node in this link');
			return false;
		}

		// Ignore if the node is the start or end waypoint
		const isStartOrEndWaypoint = this.waypoints.isStartOrEndWaypoint(node.id);
		if (isStartOrEndWaypoint || node.task !== 'HAULING') {
			return true;
		}

		const isMidWaypoint = this.waypoints.isMidWaypoint(node.id);

		// Check if we are allowed to add or remove the waypoint (eg. if it is a reverse, it has to be a waypoint)
		// Or if we are at the max limit of waypoints of this type

		if (isMidWaypoint) {
			this.waypoints.removeWaypoint(node.id);
		} else {
			// Check if we are allowed to add a new hauling waypoint
			if (!this.validationHelper.isAllowedToAddWaypoint('HAULING')) {
				return true;
			}

			// Find the index of the waypoint that we want to add it after
				// @ts-ignore flatMap is supported on most browsers
			const nodeList = node.sublink.link.sublinkss?.flatMap(x => x.nodess);
			const waypointIds: string[] = [];
			const nodeIndex = nodeList?.findIndex((n: NodeEntity) => {
				if (this.waypoints.isWaypoint(n.id)) {
					waypointIds.push(n.id);
				}

				return n.id == node.id;
			});

			// We tried but couldn't find the node
			if (nodeIndex === -1) {
				return true;
			}

			// We are certain there will always be a next node at this point
			const nextNode = nodeList[nodeIndex + 1];

			// We got the id from the waypoint list, so we can guarantee that it exists
			const previousWaypoint = this.waypoints.getWaypoint(waypointIds[waypointIds.length - 1])!;
			const direction = previousWaypoint?.direction ?? 'forward';

			// Calculate the heading, account for the direction of the path
			const heading = calcHeading(node, nextNode) - (direction === 'forward' ? 0 : 180) % 360;

			const waypoint = new Waypoint({
				id: node.id,
				northing: node.northing,
				easting: node.easting,
				isSnapped: false,
				shortClicked: true,
				task: node.task,
				isConfirmed: true,
				heading,
				direction,
			});

			this.waypoints.insertWaypoint(previousWaypoint.id, waypoint);
		}

		// Rerender the path after the waypoints have changed
		this.processPathUpdate()
			.then(() => !!callback && callback(!this.generationHelper.hasErrored));

		return true;
	}

	public isEditing() {
		return this.isEditMode;
	}

	public isIgnoringDragAction() {
		return this.ignoreDragAction;
	}

	public startIgnoringDragAction() {
		this.ignoreDragAction = true;
	}

	public stopIgnoringDragAction() {
		this.ignoreDragAction = false;
	}

	public confirmPath(): boolean {
		if (this.isProcessing()) return false;

		// Check there are no errors
		if (!this.mapEventHandler.getController().isActionConfirmAllowed()) {
			return false;
		}

		if (!!this.link && !this.isConfirmed) {
			// Assign ids to the link and add it to the map store
			this.validationHelper.assignIdsForLink(this.link);
			// this.mapStore.addPath(this.link);

			// Add the connections if they exist
			this.link.addPreviousLinks(this.waypoints.getPreviousConnections());
			this.link.addNextLinks(this.waypoints.getNextConnections());

			this.isConfirmed = true;

			// Rerender the path to the correct display before triggering the save
			this.renderHelper.setConfirmingPath(true, this.link);

			this.mapTracker.addChange(new CreatePathCommand(this.link));

			this.confirmOtherLinks();

			return true;
		}

		return false;
	}

	public confirmOtherLinks() {

	}

	public cancelPath() {
		// NOTE: If the path already exists on the map, we will need to reset it here
		this.removeWaypoints();

		// We are now creating a new path
		this.setCreating();
		this.propertiesHelper.resetProperties();
		this.renderHelper.setDefaultCursor();
	}
}