import * as React from 'react';
import { ImportVersionEntity } from '../../../Models/Entities';
import { importEditStatusOptions } from '../../../Models/Enums';
import { useEffect, useState } from 'react';
import { disableMapDomEvents } from '../Map/Helpers/MapDOMEvents';
import { Button } from '../../Components/Button/Button';
import classNames from 'classnames';
import classnames from 'classnames';
import displayComingSoonToast from '../../../Util/ComingSoon';
import { store } from '../../../Models/Store';
import { MAT_VERSION, SERVER_URL } from '../../../Constants';
import { observable } from 'mobx';
import SearchForm from '../../Components/SearchForm/SearchForm';
import MapController from '../Map/MapController';
import AutoSaveIndicator from './AutoSaveIndicator';
import ImportVersionStatusIndicator from './ImportVersionStatusIndicator';
import { IMenuShownStatus } from '../EditMap';
import { validateFullMap } from '../Map/Helpers/FullMapValidation';
import PathValidator from '../Map/MapValidators/PathValidator';
import { setCustomTag } from '../Map/Helpers/MapUtils';

export enum MenuItemAction {
	ImportMap,
	ImportAreas,
	ImportTerrain,
	ImportBackground,
	PublishAndExport,
	MapParameters,
	ArchiveMap,
}

interface MenuOptions {
	/* Display text of the menu item */
	name: string;

	/* Set an icon to display instead of displaying the name */
	icon?: string;

	/* Use a custom component inside the button instead of the name and icon above */
	customComponent?: React.ReactNode;

	/* Replace the button with another component */
	customButton?: (closeMenu: () => void) => React.ReactNode;

	/* Add any additional classnames */
	className?: string;

	/* Display shortcut text next to this menu item */
	shortcutText?: string;

	/* Any child menu options */
	children?: MenuOptions[];

	/* Is the menu item checkable */
	isCheckable?: boolean;

	/* Is this menu item checkable by default */
	isChecked?: boolean;

	/* Function that is called when a checkable menu item is checked (the onClick method will also be run) */
	onCheck?: (isChecked: boolean) => void;

	/* Does the current item have a line separator after it */
	separatorAfter?: boolean;

	/* Function that is called when the button is clicked */
	onClick?: (isChecked?: boolean) => void;

	/* Should the whole menu disappear once a user has clicked an item in it */
	hideMenuWhenActionClicked?: boolean;
}

function getFileMenu(importVersion: ImportVersionEntity, onMenuItemClick: (action: MenuItemAction) => void): MenuOptions {
	const mapName = importVersion.map.name;
	const importName = importVersion.name;

	return {
		name: `${mapName} / ${importName}`,
		hideMenuWhenActionClicked: false,
		// Had to add a non-breaking space to prevent flexbox from removing the space
		customComponent: <><span className="grey-text">{mapName} /</span><span>&nbsp;{importName}</span></>,
		children: [{
			name: 'Import Files',
			children: [
			// HITMAT-2131 (Menu item removed temporarily)
			// {
			// 	name: 'AHS Current Map Data',
			// 	onClick: () => onMenuItemClick(MenuItemAction.ImportMap),
			// }, 
			{
				name: 'AHS Current Area Data',
				onClick: () => onMenuItemClick(MenuItemAction.ImportAreas),
			}, {
				name: 'Drivable Area Data',
				onClick: () => onMenuItemClick(MenuItemAction.ImportTerrain),
			}, {
				name: 'Background Image Data',
				onClick: () => onMenuItemClick(MenuItemAction.ImportBackground),
			}],
		}, {
			name: 'Publish & Export',
			onClick: () => onMenuItemClick(MenuItemAction.PublishAndExport),
			separatorAfter: true,
		}, {
			name: 'Archive',
			onClick: () => onMenuItemClick(MenuItemAction.ArchiveMap),
			separatorAfter: true,
		}, {
			name: 'Map Parameters',
			onClick: () => onMenuItemClick(MenuItemAction.MapParameters),
		}],
	};
}

function setShowHideCustomTag (tagKey: string, valuePrefix: string, isChecked: boolean) {
	const option = isChecked ? 'show' : 'hide';
	const tagValue = `${valuePrefix}-${option}`;
	setCustomTag(tagKey, tagValue);
}

function getViewMenu(setHiddenStatus: (status: boolean, viewType: string) => void): MenuOptions {
	return {
		name: 'View',
		hideMenuWhenActionClicked: false,
		children: [{
			name: 'Labels',
			isCheckable: true,
			isChecked: true,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "labels");
				setShowHideCustomTag('show-hide', 'labels', isChecked!);
			},
			// children: [{
			// 	name: 'Areas',
			// 	isCheckable: true,
			// 	onClick: displayComingSoonToast,
			// }, {
			// 	name: 'Bays',
			// 	children: [{
			// 		name: 'ID',
			// 		isCheckable: true,
			// 		onClick: displayComingSoonToast,
			// 	}, {
			// 		name: 'Sequence number',
			// 		isCheckable: true,
			// 		onClick: displayComingSoonToast,
			// 	}],
			// }, {
			// 	name: 'Paths',
			// 	separatorAfter: true,
			// 	children: [{
			// 		name: 'Link ID',
			// 		isCheckable: true,
			// 		onClick: displayComingSoonToast,
			// 	}, {
			// 		name: 'Sublink ID',
			// 		isCheckable: true,
			// 		onClick: displayComingSoonToast,
			// 	}, {
			// 		name: 'Node task',
			// 		isCheckable: true,
			// 		onClick: displayComingSoonToast,
			// 	}],
			// }, {
			// 	name: 'Locations',
			// 	isCheckable: true,
			// 	onClick: displayComingSoonToast,
			// }, {
			// 	name: 'Beacons',
			// 	isCheckable: true,
			// 	onClick: displayComingSoonToast,
			// }],
		}, {
			name: 'Background image',
			isCheckable: true,
			isChecked: true,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "backgroundImage");
				setShowHideCustomTag('show-hide', 'background-image', isChecked!);
			},
		}, {
			name: 'Drivable area',
			isCheckable: true,
			isChecked: true,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "driving_area");
				setShowHideCustomTag('show-hide', 'drivable-area', isChecked!);
			},
		
		}, {
			name: 'Sublinks',
			isCheckable: true,
			isChecked: true,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "sublink");
				setShowHideCustomTag('show-hide', 'sublinks', isChecked!);
			},
		}, {
			name: 'Nodes',
			isCheckable: true,
			isChecked: true,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "node");
				setShowHideCustomTag('show-hide', 'nodes', isChecked!);
			},
		}, {
			name: 'Turn signals',
			isCheckable: true,
			isChecked: false,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "signal");
				setShowHideCustomTag('show-hide', 'turn-signals', isChecked!);
			},
		}, {
			name: 'Dynamic connections',
			isCheckable: true,
			isChecked: false,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "dynamicConnections");
				setShowHideCustomTag('show-hide', 'dynamic-connections', isChecked!);
			},
		}],
	};
}

function getCheckMenu(map: MapController, setHiddenStatus: (status: boolean, viewType: string) => void): MenuOptions {
	return {
		name: 'Check',
		children: [{
			name: 'Speed restrictions',
			isCheckable: true,
			isChecked: false,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "speedLimits");
				setShowHideCustomTag('show-hide', 'speed-limits', isChecked!);
			},
		}, {
			name: 'Connectivity end points',
			isCheckable: true,
			isChecked: false,
			onClick: (isChecked) => {
				setHiddenStatus(isChecked!, "connectivityEndPoints");
				setShowHideCustomTag('show-hide', 'connectivity-end-points', isChecked!);
			},
		}, {
			name: 'Path interferences',
			isCheckable: true,
			isChecked: false,
			onClick: (isChecked) => {
				// console.log(isChecked);
				map.getMapLookup().isCheckPathInterference = isChecked!;
				const pathValidator = new PathValidator(map);
				if (isChecked) {
					pathValidator.validatePathsInterference();
				} else {
					// show sublink drving zone errors styling
					pathValidator.validateSublinksDrivingZones(!isChecked);
				}
				setShowHideCustomTag('check', 'path-interference', isChecked!);
			},
		}, {
			name: 'Run full map check',
			onClick: () => {
				validateFullMap(map, true, undefined, true);
				setCustomTag('check', 'run-full-map-check');
			},
		}],
	};
}

function getHelpMenu(): MenuOptions {
	return {
		name: 'Help',
		className: 'help-menu',
		children: [{
			name: 'Search',
			separatorAfter: true,
			className: 'help-search',
			customButton: hideMenu => {
				return (
					<SearchForm
						className="small help-search"
						model={observable({ searchTerm: '' })}
						clickToClear={false}
						onClick={displayComingSoonToast}
						displaySearchButton={false}
						onBlur={hideMenu}
					/>
				);
			},
		}, {
			name: 'Documentation',
			onClick: openUrl('https://solutionlinkagecare.hcma.com.au/pages/viewpage.action?pageId=28443604'),
		}, {
			name: 'Release notes',
			separatorAfter: true,
			onClick: openUrl('https://solutionlinkagecare.hcma.com.au/display/ADVC/Hitachi+AHS+-+Release+Note+Current'),
		}, {
			name: 'Keyboard shortcuts',
			onClick: displayComingSoonToast,
		}, {
			name: 'Tutorials',
			onClick: displayComingSoonToast,
		}, {
			name: 'Productivity tips',
			separatorAfter: true,
			onClick: displayComingSoonToast,
		}, {
			name: 'Report an issue',
			onClick: openUrl('https://solutionlinkagecarej.hcma.com.au/plugins/servlet/desk/portal/3/create/61 '),
		}, {
			name: 'Provide feedback',
			separatorAfter: true,
			onClick: openUrl('https://solutionlinkagecarej.hcma.com.au/plugins/servlet/desk/portal/3/create/59 '),
		}, {
			name: 'About',
			customButton: () => {
				return (
					<div className='about'>
							<div className="">About</div>
							<div className='mat-version'>ver {MAT_VERSION}</div>
					</div>
				);
			},
		}],
	};
}

function getMenuItems(
	importVersion: ImportVersionEntity,
	onMenuItemClick: (action: MenuItemAction) => void,
	map: MapController,
	setHiddenStatus: (status: boolean, viewType: string) => void,
	): MenuOptions[] {
	return [
		{
			name: 'Home',
			icon: 'home',
			onClick: () => saveAndGoHome(map, importVersion),
		},
		getFileMenu(importVersion, onMenuItemClick),
		getViewMenu(setHiddenStatus),
		getCheckMenu(map, setHiddenStatus),
		getHelpMenu(),
	];
}

async function saveAndGoHome(map: MapController, importVersion: ImportVersionEntity) {
	const tracker = map.getTracker();
	let timeoutId: NodeJS.Timeout | undefined;
	timeoutId = setInterval(async () => {
		if (tracker.isSaving) {
			return;
		} else {
			if (timeoutId) {
				clearInterval(timeoutId);
			}
			timeoutId = undefined;
		}

		store.routerHistory.push(`${SERVER_URL}/maps/${importVersion.map.id}`);
	}, 0);
}

const openUrl = (url: string) => {
	return () => window.open(url, '_blank');
};

interface IMenubarProps {
	importVersion: ImportVersionEntity;
	onMenuItemClick: (action: MenuItemAction) => void;
	map: MapController;
	setHiddenStatus: (status: boolean, viewType: string) => void;
}

export default function MenuBar(props: IMenubarProps) {
	const { importVersion, onMenuItemClick, map, setHiddenStatus } = props;

	useEffect(() => disableMapDomEvents('map-menu'));

	const menuItems = getMenuItems(importVersion, onMenuItemClick, map, setHiddenStatus);

	return (
		<div className={classNames('menu-bar')} id="map-menu">
			<ul>
				{menuItems.map(menuItem => <TopLevelMenuItem key={menuItem.name} menu={menuItem} />)}
			</ul>

			<EvaluationModeDisclaimer />

			<div className="version-status">
				<AutoSaveIndicator map={map} lastSaved={importVersion?.lastSave}/>
				Status:
				<ImportVersionStatusIndicator
					importVersionEntity={importVersion}
					eventHandler={map.getEventHandler()}
				/>
			</div>
		</div>
	);
}

function EvaluationModeDisclaimer() {
	if (!store.isTestMineSite) {
		return null;
	}

	return (
		<div className="evaluation-mode-disclaimer">
			<div>
				Beta version evaluation mode
			</div>
		</div>
	);
}

export const TopLevelMenuItem = ({ menu }: { menu: MenuOptions }) => {
	const [isExpanded, setExpanded] = useState(false);

	const containsCheckableItems = menu.children?.some(child => child.isCheckable) ?? false;

	return (
		<li className={classNames(isExpanded ? 'active' : undefined, menu.className)}>
			<div className="buttons">
				<Button
					buttonProps={{
						onClick: () => {
							if (menu.onClick) {
								menu.onClick();
							}

							if (menu.children) {
								setExpanded(!isExpanded);
							}
						},
						onBlur: e => {
							/* The relevant lifecycle of a dom click event is: mousedown -> blur -> mouseup -> click
							 * The blur and click event conflict with each other because the blur event is triggered
							 * by clicking. To fix this, we check if the target of the click event is different to
							 * the target of the blur event.
							 */
							const eventTarget: any = e.currentTarget.parentElement?.parentElement ?? e.currentTarget;
							if (!eventTarget.contains(e.relatedTarget as Node)) {
								setExpanded(false);
							} else if (menu.hideMenuWhenActionClicked === false) {
								(e.target as any).focus();
							}
						},
					}}
					className={menu.children ? classNames(`arrow-${menu.icon}`,
						'arrow', `icon-chevron-${isExpanded ? 'up' : 'down'}`, 'icon-right') : undefined}
				>
					{!menu.icon ? (!menu.customComponent ? menu.name : menu.customComponent)
						: <span className={`icon-${menu.icon} icon-only`} />}
				</Button>
			</div>

			{/* Render any child elements */}
			{menu.children === undefined ? <></> : (
				<>
					<ul className={classNames('sublist',
						isExpanded ? 'expanded' : undefined,
						containsCheckableItems ? 'checkable-menu' : undefined)}
					>
						{menu.children.map(childMenu => (
							<MenuItem
								key={childMenu.name}
								menu={childMenu}
								hideMenu={() => {
									if (menu.hideMenuWhenActionClicked !== false) {
										setExpanded(false);
									}
								}}
							/>
						))}
					</ul>
				</>
			)}
		</li>
	);
};

export const MenuItem = ({ menu, hideMenu }: { menu: MenuOptions, hideMenu: () => void }) => {
	const [isExpanded, setExpanded] = useState(false);
	const [isChecked, setChecked] = useState(menu.isChecked ?? false);

	const containsCheckableItems = menu.children?.some(child => child.isCheckable) ?? false;

	const getButton = () => {
		return (
			<Button
				className={menu.isCheckable && isChecked ? classNames('icon-check', 'icon-left') : undefined}
				onClick={() => {
					if (hideMenu) {
						hideMenu();
					}

					if (menu.isCheckable) {
						if (menu.onCheck) {
							menu.onCheck(!isChecked);
						}

						setChecked(!isChecked);
					}

					if (menu.onClick) {
						menu.onClick(!isChecked);
					}
				}}
			>
				<span className="button-content">
					{!menu.icon ? (!menu.customComponent ? menu.name : menu.customComponent)
						: <span className={`icon-${menu.icon} icon-only`} />}
				</span>

				{!menu.shortcutText ? undefined : <span className="grey-text">{menu.shortcutText}</span>}

				{!menu.children ? undefined : <span className="icon-chevron-right icon-only" />}
			</Button>
		);
	};

	return (
		<li
			className={classnames(isExpanded ? 'active' : undefined,
				menu.separatorAfter ? 'separator' : undefined,
				menu.className)}
			onMouseOver={() => menu.children && setExpanded(true)}
			onMouseOut={() => menu.children && setExpanded(false)}
		>

			{menu.customButton ? menu.customButton(hideMenu) : getButton()}

			{menu.children === undefined ? <></> : (
				<>
					<ul className={classNames('sublist',
						isExpanded ? 'expanded' : undefined,
						containsCheckableItems ? 'checkable-menu' : undefined)}
					>
						{menu.children.map(childMenu => (
							<MenuItem key={childMenu.name} menu={childMenu} hideMenu={hideMenu} />
						))}
					</ul>
				</>
			)}
		</li>
	);
};
