/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';

// % protected region % [Add any extra imports here] on begin
import {IMapLockSession, store} from '../../Models/Store';
import { ImportVersionEntity } from '../../Models/Entities';
import { action, observable, runInAction } from 'mobx';
import { EditMap } from '../MapComponents/EditMap';
import { LoadView } from '../Components/Shared/LoadView';
import axios, {AxiosResponse} from 'axios';
import alertToast from 'Util/ToastifyUtils';
import { MapLockedModal } from 'Views/Components/Shared/MapLockedModal';
import moment from 'moment';
import {MAP_LOCK_REFRESH_INTERVAL_SECONDS, SERVER_URL} from 'Constants';
import type {IUserDetails} from "../../Models/UserEntity";
import MapErrorModal, {MapLoadErrorModal} from "../Components/Shared/MapLoadErrorModal";

interface MapErrorDetails {
	lastSave: string,
	lockOwner: IUserDetails,
	mapId: string,
}

interface LockErrorDetails {
	error: string,
	mapErrorDetails: MapErrorDetails
}

interface LockSuccessDetails {
	lockKey: string,
	expiryTime: string,
}

interface LoadMapResponse extends LockSuccessDetails {
	importVersion: any,
	error: LockErrorDetails
}

type LockRequestResponse = LockSuccessDetails & LockErrorDetails;
// % protected region % [Add any extra imports here] end

export interface MapEditPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] on begin
class MapEditPage extends React.Component<RouteComponentProps<{ id: string }>> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] on begin
	@observable
	private versionEntity: ImportVersionEntity;

	@observable
	private isLoading: boolean = true;

	@observable
	private hasLock: boolean = false;

	private lockOwner: IUserDetails;
	private importVersionEntityId: string;
	private lastSave: string;
	private mapId: string;
	private intervalId: NodeJS.Timeout | undefined;

	@observable
	private error: string | undefined;

	async componentDidMount() {
		this.importVersionEntityId = this.props.match.params.id;

		await this.fetchMapVersionEntity();
	}

	private stopLockRenewal = () => {
		if (!!this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = undefined;
		}
	}

	async componentWillUnmount() {
		store.mapController = undefined;

		await this.releaseLockRequest();
	}

	private renewLock = async () => {
		const mapLockSession = this.getLockDetails();
		if (!mapLockSession) {
			console.warn('releaseLockRequest: No matching mapLockSession found.');
			return;
		}
		const { lockKey } = mapLockSession;

		let isSuccess = false;

		try {
			const { data }: AxiosResponse<LockRequestResponse> = await axios
				.post('/api/entity/ImportVersionEntity/renewLock',
					{ ImportVersionId: this.importVersionEntityId, LockKey: lockKey });

			if (data.error == null) {
				isSuccess = true;
			} else {
				this.setLockError(data.mapErrorDetails);
			}
		} catch (e) {
			alertToast('Failed to renew lock');
		}

		if (!isSuccess) {
			this.stopLockRenewal();
		}

		runInAction(() => {
			this.hasLock = isSuccess;
		});
	}

	private releaseLockRequest = async () => {
		// Reset refresh lock timeout
		this.stopLockRenewal();

		const mapLockSession = this.getLockDetails();
		if (!mapLockSession) {
			console.warn('releaseLockRequest: No matching mapLockSession found.');
			return;
		}
		const { lockKey } = mapLockSession;

		try {
			const { data }: AxiosResponse<LockRequestResponse> = await axios
				.post('/api/entity/ImportVersionEntity/releaseLock', {
					LockKey: lockKey,
					ImportVersionId: this.importVersionEntityId
				})

			if (data.error != null) {
				console.error('Unable to release lock', data.error);
			}

			// Remove the lock details from the session
			this.unsetLockDetails();
		} catch (e) {
			// No need to notify the user, this lock will expire anyway
			console.error('Unable to release lock', e);
		}
	}

	private fetchMapVersionEntity = async () => {
		const id = this.importVersionEntityId;
		const mapLockSession = this.getLockDetails();
		const lockKeyString = false && mapLockSession?.lockKey ? `/${mapLockSession.lockKey}` : '';

		try {
			const versionRequest: AxiosResponse<LoadMapResponse> =
				await axios.get(`${SERVER_URL}/api/entity/ImportVersionEntity/LoadMap/${id}${lockKeyString}`);

			const { data } = versionRequest;

			if (data.error != null) {
				this.setLockError(data.error.mapErrorDetails);
				this.setLoading(false);
				return;
			}

			runInAction(() => {
				this.versionEntity = new ImportVersionEntity(data.importVersion);
				this.hasLock = true;
			});

			this.setLockDetails(data.lockKey);
			this.setLoading(false);

			this.intervalId = setInterval(this.renewLock, MAP_LOCK_REFRESH_INTERVAL_SECONDS * 1000);
		} catch (e) {
			runInAction(() => {
				this.error = 'Error';
			})
		}
	}

	@action
	private setLockDetails = (lockKey: string) => {
		this.hasLock = true;

		store.setMapLockSession(this.importVersionEntityId, lockKey);
	}

	@action
	private unsetLockDetails = () => {
		this.hasLock = false;

		store.deleteMapLockSession(this.importVersionEntityId);
	}

	private getLockDetails = (): IMapLockSession | undefined => {
		return store.getMapLockSession(this.importVersionEntityId);
	}

	@action
	private setLockError = (errorDetails: MapErrorDetails) => {
		this.hasLock = false;
		this.lockOwner = errorDetails.lockOwner ?? {
			email: 'unknown'
		};
		this.mapId = errorDetails.mapId;

		const guessedTZ = moment.tz.guess();
		this.lastSave = errorDetails.lastSave
			? moment.utc(errorDetails.lastSave).tz(guessedTZ).format('DD/MM/YYYY HH:mm:ss')
			: 'N/A';
	}

	@action
	private setLoading = (isLoading: boolean) => {
		this.isLoading = isLoading
	}

	@action
	private closeModal = () => {
		// If we don't have a mapId yet, resort to just going back one page
		if (!!this.mapId) {
			store.routerHistory.push(`/maps/${this.mapId}`);
		} else {
			store.routerHistory.goBack();
		}
	};

	getMapEditPageContent() {
		if (this.error !== undefined) {
			return <MapLoadErrorModal onCloseModal={this.closeModal} />
		}

		if (this.isLoading) {
			return <LoadView text="Loading map data..." timerInterval={25} />;
		}

		if (!this.hasLock) {
			if (this.lockOwner !== undefined && this.lockOwner.id === store.userId) {
				return <MapErrorModal
					title="Map Session"
					errorMessage="The map is open in another tab or window."
					onCloseModal={this.closeModal} />;
			}
			
			if (this.lockOwner == null) {
				return <MapErrorModal
					onCloseModal={this.closeModal}
					IsExpired
					title="Map Session"
					errorMessage="The map session has expired. Please reload the map or return home." />
			}

			return (
				<MapLockedModal
					onCloseModal={this.closeModal}
					lockOwner={this.lockOwner}
					lastSave={this.lastSave}
				/>
			);
		}

		return <EditMap version={this.versionEntity} />;
	}
	// % protected region % [Add class properties here] end

	render() {
		const {
			match,
			location,
			history,
			staticContext,
			// % protected region % [Destructure any additional props here] off begin
			// % protected region % [Destructure any additional props here] end
		} = this.props;

		// % protected region % [Add logic before rendering contents here] off begin
		// % protected region % [Add logic before rendering contents here] end

		// eslint-disable-next-line prefer-const
		let contents = (
			// eslint-disable-next-line max-len
			<SecuredPage groups={['Super Administrators', 'MineUser', 'HitachiAdmin']}>
				{
				// % protected region % [Alter navigation here] on begin
				}
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					{
					// % protected region % [Add code for 5048882d-dcbd-4e01-a350-c142df8fa1b8 here] on begin
					}
					{this.getMapEditPageContent()}
					{
					// % protected region % [Add code for 5048882d-dcbd-4e01-a350-c142df8fa1b8 here] end
					}
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] off begin
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default MapEditPage;
// % protected region % [Override export here] end
