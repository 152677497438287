import InputWrapper from "../../../Components/Inputs/InputWrapper";
import * as React from "react";
import * as uuid from 'uuid';
import {ChangeEvent, ForwardedRef, forwardRef, useEffect, useRef, useState} from "react";
import If from "../../../Components/If/If";
import {observer} from "mobx-react";
import {get, isObservable, isObservableMap, isObservableProp, reaction, runInAction} from "mobx";
import InlineValidation from "./InlineValidation";
import {store} from "../../../../Models/Store";
import {TextArea} from "semantic-ui-react";
import {unstable_batchedUpdates} from "react-dom";

// Only allow properties that are assignable to an input field
type ValidTypes = string | number | undefined;
type ExtractKeyType<T extends { [P in K]?: ValidTypes }, K extends keyof T> = T[K] extends ValidTypes ? T[K] : never;

export interface InputFieldProps<
        Type extends { [P in Key]?: ValidTypes },
        Key extends keyof Type>
    {

    model: Type; // Model should be observable
    modelProperty: Key;

    // Observable object where the errors can be assigned to
    errorsObject?: { [key in string]: string };

    label?: string;
    propertyUnit?: string;

    // Input properties
    isReadOnly?: boolean;
    isNumber?: boolean;
    maxLength?: number;
    isTextArea?: boolean;
    displayBlank?: boolean;

    renderDisplayValue?: (value: ExtractKeyType<Type, Key>) => ValidTypes;
    alterValueBeforeConfirm?: (value: ExtractKeyType<Type, Key>) => ExtractKeyType<Type, Key>
    onAfterChange?: () => void;

    // The modification has been confirmed and assigned to the model
    onUpdate?: (value: ExtractKeyType<Type, Key>) => void;

    // Check if the new valid is value before assigning it to the model
    onValidateInput?: (value: ExtractKeyType<Type, Key>, model: Type, property: Key) => string | undefined;
}

interface InnerTextInputProps {
    // Input properties
    id?: string;
    name?:string;
    value?: string | number;
    className?: string;
    placeholder?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    inputProps?: React.InputHTMLAttributes<Element>;
    errors?: string | string[];
    inputClassName?: string;
    isNumber?: boolean;
    maxLength?: number;

    // Events
    onAfterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAndBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputField = observer(<
    Type extends { [P in Key]?: ValidTypes },
    Key extends keyof Type
    >(
        props: InputFieldProps<Type, Key>
    ) => {

    const {
        model,
        modelProperty,
        errorsObject,
        label,
        propertyUnit,
        maxLength,
        isReadOnly,
        isNumber,
        displayBlank,
        isTextArea,
        onAfterChange,
        alterValueBeforeConfirm,
        onUpdate,
        onValidateInput
    } = props;

    const modelPropertyString = modelProperty.toString();

    const id = `${label?.replaceAll(' ', '_') ?? 'default'}_${modelPropertyString}`;
    const inputClassName = `unit-${propertyUnit?.length ?? 0}`;

    const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

    const getErrorKey = () => !!model['_clientId']
        ? `${modelPropertyString}_${model['_clientId']}`
        : modelPropertyString;

    const getValueAsModelType = (): ExtractKeyType<Type, Key> =>
        (typeof model[modelProperty] === 'number' ? Number(displayValue) : displayValue) as ExtractKeyType<Type, Key>;

    const getModelValue = (): ExtractKeyType<Type, Key> => model[modelProperty] as ExtractKeyType<Type, Key>;

    const renderDisplayValue = (value: ExtractKeyType<Type, Key>): ValidTypes =>
        !!props.renderDisplayValue ? props.renderDisplayValue(value) : value;

    const [valueUpdated, setValueUpdated] = useState(false);
    const [error, setError] = useState(
        !!errorsObject && !!errorsObject[getErrorKey()] ? errorsObject[getErrorKey()] : undefined);
    const [displayConfirmCancelButtons, setDisplayConfirmCancelButtons] = useState(false);
    const [displayValue, setDisplayValue] = useState(
        renderDisplayValue(model[modelProperty] as any)); // We can be certain that the modelProperty matches the correct type
    const [isFocussed, setIsFocussed] = useState(false);
    const [globalConfirmEnabled, setGlobalConfirmEnabled] =
        useState(error === undefined);

    const setGlobalConfirmState = (enabled: boolean) => {
        const { optionalMapController } = store;

        // Ignore if the mapController is not defined
        if (!optionalMapController) {
            return;
        }

        // Committing successive valid/invalid commands to the global confirm will create invalid enabling/disabling
        // of the global confirm
        if (globalConfirmEnabled === enabled) {
            return;
        }

        setGlobalConfirmEnabled(enabled);

        // If the button doesn't already exist, don't disable/enable it
        // And if the current status doesnt
        if (optionalMapController.isDisplayConfirmButton) {
            optionalMapController.getEventHandler().emit('toggleConfirmCreation', true, !enabled);
        }
    }

    const updateValue = (value: ExtractKeyType<Type, Key>) => {
        setDisplayValue(renderDisplayValue(value));
    };

    const updateModelValue = () => {
        const newValue = getValueAsModelType();

        const afterProcessValue = !!alterValueBeforeConfirm ? alterValueBeforeConfirm(newValue) : newValue;

        runInAction(() => {
            model[modelProperty] = afterProcessValue as any;
        });

        updateValue(afterProcessValue);

        if (!!onUpdate) {
            onUpdate(getValueAsModelType());
        }
    }

    const hideConfirmCancelButtons = () => {
        setDisplayConfirmCancelButtons(false);
    }

    // Update the display value if the model has changed
    useEffect(() => setDisplayValue(renderDisplayValue(model[modelProperty] as any)), [model]);

    useEffect(() => (() => {
        // Re-enable the global input upon unmount of the InputField component
        setGlobalConfirmState(error === undefined);
    }), []);

    useEffect(() => {
            if (!errorsObject) {
                return;
            }

            return reaction(() => errorsObject[getErrorKey()], e => setError(e));
        }, [errorsObject]);

    useEffect(() => reaction(() => model[modelProperty], v => {
        if (valueUpdated) {
            return;
        }

        unstable_batchedUpdates(() => {
            updateValue(v as ExtractKeyType<Type, Key>);

            // If it's been updated externally, we can be confident there is no longer an error on the model
            setError(x => {

                // Only if there was previously an error do we want to re-enable the global confirm button
                // In the case there wasn't a previous error, it may re-enable the global confirm with other errors
                // on the page
                if (x !== undefined) {
                    setGlobalConfirmState(true);
                }

                return undefined;
            });
            hideConfirmCancelButtons();
        });
    }), [model]);

    const confirmChange = (): boolean => {
        if (!valueUpdated) {
            return false;
        }

        const _error = !!onValidateInput ? onValidateInput(getValueAsModelType(), model, modelProperty) : undefined;
        if (_error !== error) {
            setError(_error);
        }

        updateErrorsObject(_error);

        if (!_error) {
            updateModelValue();
            setValueUpdated(false);
        }

        setGlobalConfirmState(_error === undefined);

        return !_error;
    };

    const cancelChange = () => {
        setValueUpdated(false);

        const value = getModelValue();
        updateValue(value);

        const validationResult = !!onValidateInput ? onValidateInput(value, model, modelProperty) : undefined;

        setError(validationResult);
        setGlobalConfirmState(validationResult === undefined);
    };

    const onAfterValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValueUpdated(true);
        // Don't update the inner value until validated
        setDisplayValue(event.target.value);

        if (!!onAfterChange) {
            onAfterChange();
        }
    }

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            confirmChange();

            // Reshow it if they have been hidden
            setDisplayConfirmCancelButtons(true);
        } else if (event.key === 'Escape') {
            cancelChange();
        }
    };

    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if (isReadOnly) {
            event.target.blur(); // Make sure to blur the input so keyboard events dont trigger
            return;
        }

        setIsFocussed(true);
        setDisplayConfirmCancelButtons(true);
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!isFocussed) {
            return; // If the input was never focussed to begin with
        }

        setIsFocussed(false);

        // Ignore if clicking on the inline validation buttons
        if (event.relatedTarget?.parentElement?.className.includes('inline-validation')) {
            return;
        }

        if (!confirmChange()) {
            setDisplayConfirmCancelButtons(false);
        }
    };

    const updateErrorsObject = (error: string | undefined) => {
        if (errorsObject === undefined) {
            return;
        }

        runInAction(() => {
            if (error !== undefined) {
                errorsObject[getErrorKey()] = error;
            }

            // Clear the error from the errors object
            if (!!errorsObject[getErrorKey()]) {
                delete errorsObject[getErrorKey()];
            }

        });
    }

    if (isTextArea) {
        return (
            <div className="properties-input input-column">
                <If condition={label !== undefined}>
                    <div className="label">
                        <p>{label}</p>
                    </div>
                </If>
                <TextArea
                    value={model[modelProperty]}
                    readOnly={isReadOnly}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    rows={5} // Add as a prop if it needs to be changeable (originally added for segment panel description)
                />
            </div>
        );
    }

    return (
        <>
            <div className="properties-input" id={id}>
                {(isFocussed && error !== undefined)
                    ? (
                        <div className="inline-error-box">
                            <div className="inline-error-message">
                                {error}
                            </div>
                        </div>
                    ) : null }
                <If condition={label !== undefined}>
                    <div className="label">
                        <p>{label}</p>
                    </div>
                </If>
                <div className="info-fields">
                    <InnerTextInputField
                        ref={inputRef}
                        className={!!error ? 'input-group--error' : undefined}
                        value={displayBlank ? '' : displayValue}
                        isReadOnly={isReadOnly}
                        isNumber={isNumber}
                        inputClassName={inputClassName}
                        onAfterChange={onAfterValueChange}
                        maxLength={maxLength}
                        inputProps={{
                            onBlur: onBlur,
                            onKeyDown: onKeyPress,
                            onFocus: onFocus,
                        }}/>
                    <p>{propertyUnit}</p>
                    { displayConfirmCancelButtons
                        ? (
                            <InlineValidation
                                onTick={() => {
                                    if (!valueUpdated) {
                                        hideConfirmCancelButtons();
                                        return;
                                    }

                                    if (confirmChange()) {
                                        hideConfirmCancelButtons();
                                    } else if (inputRef.current !== null) {
                                        inputRef.current.focus();
                                    }
                                }}
                                onCross={() => {
                                    cancelChange();
                                    hideConfirmCancelButtons();
                                }}
                                unit={propertyUnit}
                                hideErrorMessage={() => {

                                }}
                            />
                        )
                        : undefined }
                </div>
            </div>
        </>
    );
});

const InnerTextInputField = forwardRef((
    props: InnerTextInputProps,
    ref: ForwardedRef<HTMLInputElement>) => {

    const {
        value,
        name,
        className,
        inputClassName,
        id = uuid.v4(),
        maxLength,
        isNumber,
        isRequired,
        isDisabled,
        isReadOnly,
        placeholder,
        errors,
        inputProps,
        onAfterChange,
        onChangeAndBlur,
    } = props;

    const fieldId = `${id}-field`;

    let valueWhenFocussed: string | number | undefined = undefined;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (isNumber) {
            const textValue = event.target.value.trim();
            const numberValue = Number(textValue);

            // Prevent parent from updating the result
            if (Number.isNaN(numberValue) && textValue !== '-') {
                return;
            }
        }

        if (onAfterChange) {
            onAfterChange(event);
        }
    };

    const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
        if (valueWhenFocussed !== event.target.value && onChangeAndBlur) {
            onChangeAndBlur(event);
        }
    };

    const onFocus = (event: ChangeEvent<HTMLInputElement>) => {
        valueWhenFocussed = event.target.value;
    };

    return (
        <InputWrapper
            id={id}
            inputId={fieldId}
            className={className}
            isRequired={isRequired}
            errors={errors}>
            <input
                ref={ref}
                type="text"
                name={name}
                id={fieldId}
                className={inputClassName}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder}
                disabled={isDisabled}
                readOnly={isReadOnly}
                tabIndex={isReadOnly ? -1 : undefined}
                maxLength={maxLength}
                {...inputProps}
            />
        </InputWrapper>
    );
});

export default InputField;